import { memo, useCallback, useState, useMemo } from 'react'
import styled from 'styled-components'
import { i18n, getLocale } from 'inline-i18n'
import { useMutation } from '@apollo/client'
import Button from '@material-ui/core/Button'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import { useStripe } from '@stripe/react-stripe-js'
import { NavLink } from "react-router-dom"
import queryString from 'query-string'

import { PRICING, PLANS } from '../../../utils/constants'
import { loginCallback, getDiscountText, getYourSubPathname } from '../../../utils/misc'
import useError from '../../../hooks/useError'
import useDataQuery from '../../../hooks/useDataQuery'
import useInstanceValue from '../../../hooks/useInstanceValue'
import i18nReact from '../../../utils/i18nReact'

import Loading from '../../common/Loading'

import createStripeCheckoutSessionMutation from '../../../graphql/mutations/createStripeCheckoutSession'
import createStripePortalSessionMutation from '../../../graphql/mutations/createStripePortalSession'
import updateStripeSubscriptionMutation from '../../../graphql/mutations/updateStripeSubscription'
import stripeSubscriptionNextInvoiceQuery from '../../../graphql/queries/stripeSubscriptionNextInvoice'
import stripeSubscriptionChangePreviewQuery from '../../../graphql/queries/stripeSubscriptionChangePreview'

const TotalBox = styled.div`
  margin-top: 10px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 10px;
  padding: 20px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
`

const TotalLine = styled.div`
  font-size: 17px;
`

const Faded = styled.span`
  opacity: .2;
`

const UpdateSubscriptionMessage = styled.div`
  font-size: 15px;
  margin-top: 15px;
  max-width: 600px;
`

const NextChargeMessage = styled.div`
  font-size: 13px;
  margin-top: 10px;
  max-width: 260px;
  text-align: center;
`

const SaleImg = styled.img`
  width: 300px;
  margin-top: 15px;
`

const ProratedMessage = styled.div`
  font-size: 15px;
  margin-top: 15px;
  max-width: 600px;
  position: relative;
  min-height: 20px;
`

const OfflineMessage = styled.div`
  font-size: 15px;
  margin-top: 15px;
  max-width: 600px;
  color: ${({ theme }) => theme.palette.warning.main};
`

const CreditMessage = styled.div`
  font-size: 15px;
  margin-top: 15px;
  max-width: 600px;
`

const Total = styled.span`
  font-weight: bold;
`

const CheckoutButtonContainer = styled.div`
  margin-top: 15px;
`

const Coupon = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.error.dark};
  margin: 10px 0 5px;
  border-radius: 5px;
  display: flex;
`

const CouponIconContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.error.dark};
  padding: 0 10px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 10px;

  .MuiSvgIcon-root {
    height: 20px;
    width: auto;
  }
`

const CouponDetails = styled.div`
  padding: 7px 10px;
  font-size: 15px;
`

const CouponName = styled.div`
  font-weight: 700;
  color: ${({ theme }) => theme.palette.error.dark};
`

const CouponDiscount = styled.div`
`

const CancelButton = styled(Button)`
  margin-left: 10px;
`

const SingleLinePlanImg = styled.img`
  height: 32px;
  margin: 0 -6px;
  vertical-align: middle;
  position: relative;
  top: -2px;
`

const emptyObj = {}

const ReviewBeforeCheckout = ({
  planInfo,
  donation,
  subscriptionSelectionStatus,
  cancelUpdate,
  showing,
  legacyCredit,
  isActiveButCanceled,
  currentPeriodEndsAt,
  currentStripeCurrentCoupon,
  currentStripePlan,
  stripeCurrentSale,
  discount,
}) => {

  const [ createStripeCheckoutSession ] = useMutation(createStripeCheckoutSessionMutation)
  const [ createStripePortalSession ] = useMutation(createStripePortalSessionMutation)
  const [ updateStripeSubscription ] = useMutation(updateStripeSubscriptionMutation)
  const stripe = useStripe()
  const { online, errorDialog, setError } = useError()

  let {
    'apply-sale-to-current': applySaleToCurrentFromQueryString,
  } = queryString.parse(window.location.search)
  applySaleToCurrentFromQueryString = applySaleToCurrentFromQueryString !== undefined

  const { plan, term, showApplySaleToCurrent } = planInfo || {}
  const executeAt = useMemo(() => Date.now(), [])

  const [ submitting, setSubmitting ] = useState(false)

  const getPlanInfo = useInstanceValue(planInfo)
  const getDonation = useInstanceValue(donation)

  const relevantStripeCurrentSale = useMemo(
    () => {

      if(discount) {
        return {
          id: `whatever-you-can-afford-${discount}`,
          name: "Whatever-You-Can-Afford",
          percentOff: discount,
          duration: "repeating",
        }
      }

      const { planTermCombos="", restrictions="" } = (stripeCurrentSale || {}).metadata || {}
      const planTermCombosArray = planTermCombos.split(' ')
      const restrictionsArray = restrictions.split(' ')

      if(
        planTermCombosArray.includes(`${plan}:${term}`)
        && !(
          restrictionsArray.includes(`no-current-subscription`)
          && PLANS.indexOf(currentStripePlan) >= PLANS.indexOf(plan)
        )
      ) {
        return stripeCurrentSale
      }

      return emptyObj
    },
    [ discount, stripeCurrentSale, plan, term, currentStripePlan ],
  )

  const amount = (((PRICING[plan] || {})[term] || 0) + (donation || 0)).toFixed(2)
  const amountPerPeriod = (
    term === 'ANNUALLY'
      ? i18n("${{amount}} / year", { amount })  // eslint-disable-line no-template-curly-in-string
      : i18n("${{amount}} / month", { amount })  // eslint-disable-line no-template-curly-in-string
  )

  const proratingNeeded = !!(
    showing
    && (
      [ 'changed' ].includes(subscriptionSelectionStatus)
      || legacyCredit
    )
  )
  const getSubscriptionChangeProration = showing && [ 'changed' ].includes(subscriptionSelectionStatus)

  const { stripeSubscriptionChangePreview={} } = useDataQuery({
    stripeSubscriptionChangePreviewQuery,
    skip: (
      !getSubscriptionChangeProration
      || process.env.REACT_APP_STAGE === 'development'
    ),
    variables: {
      plan,
      term,
      donation,
      executeAt,
    },
  })

  const {
    stripeSubscriptionNextInvoice,
    refetch: refetchStripeSubscriptionNextInvoice,
    networkStatus: networkStatusStripeSubscriptionNextInvoice,
  } = useDataQuery({
    stripeSubscriptionNextInvoiceQuery,
    skip: (
      ![ 'unchanged' ].includes(subscriptionSelectionStatus)
      || isActiveButCanceled
      || process.env.REACT_APP_STAGE === 'development'
    ),
    notifyOnNetworkStatusChange: true,
    dataOnError: null,
  })

  const totalDueNow = (
    getSubscriptionChangeProration
      ? (stripeSubscriptionChangePreview.totalDueNow || 0)
      : amount - legacyCredit
  )

  const nextChargeAt = (
    getSubscriptionChangeProration
      ? stripeSubscriptionChangePreview.nextChargeAt
      : term === 'ANNUALLY' ? Date.now() + 1000*60*60*24*365 : Date.now() + 1000*60*60*24*30
  )

  const readyToUpdate = nextChargeAt != null

  const goCheckout = useCallback(
    async () => {

      try {

        setSubmitting(true)

        const { plan, term } = getPlanInfo()

        const { data: { createStripeCheckoutSession: { sessionId } } } = await createStripeCheckoutSession({
          variables: {
            successUrl: `${window.location.origin}${getYourSubPathname(plan)}?purchase-successful`,
            cancelUrl: `${window.location.origin}/subscribe?plan=${plan}&term=${term}&donation=${getDonation()}`,
            plan,
            term,
            donation: getDonation(),
            couponId: relevantStripeCurrentSale.id,
          },
        })

        stripe.redirectToCheckout({ sessionId })

      } catch(err) {
        setSubmitting(false)
        setError(err) 
      }

    },
    [ setSubmitting, getPlanInfo, createStripeCheckoutSession, getDonation, relevantStripeCurrentSale, stripe, setError ],
  )

  const updateSubscription = useCallback(
    async () => {

      try {

        setSubmitting(true)

        const { plan, term } = getPlanInfo()

        const { data: { updateStripeSubscription: user } } = await updateStripeSubscription({
          variables: {
            plan,
            term,
            donation: getDonation(),
            executeAt,
            couponId: relevantStripeCurrentSale.id,
          },
        })

        loginCallback(user)  // updates the user (NOTE: I don't think this is needed since updateStripeSubscription has saveServerResultToDexie in getMutaitonSettings. But I don't want to test now.)
        setSubmitting(false)

        // for some reason, a direct call to the refetch causes its response to be ignored by apollo
        setTimeout(() => refetchStripeSubscriptionNextInvoice(), 250)

      } catch(err) {
        setSubmitting(false)
        setError(err) 
      }

    },
    [ setSubmitting, getPlanInfo, updateStripeSubscription, getDonation, executeAt, relevantStripeCurrentSale, setError, refetchStripeSubscriptionNextInvoice ],
  )

  const goToBilling = useCallback(
    async () => {

      try {

        setSubmitting(true)

        const { data: { createStripePortalSession: { sessionUrl } } } = await createStripePortalSession({
          variables: {
            callbackUrl: `${window.location.origin}/subscribe`,
          },
        })

        window.location = sessionUrl

      } catch(err) {
        setSubmitting(false)
        setError(err) 
      }

    },
    [ setSubmitting, createStripePortalSession, setError ],
  )

  const amount_per_period = <Total>{amountPerPeriod}</Total>
  const showApplyButton = (
    [ 'unchanged' ].includes(subscriptionSelectionStatus)
    && !isActiveButCanceled
    && !!relevantStripeCurrentSale.id
    && (
      applySaleToCurrentFromQueryString
      || showApplySaleToCurrent
    )
  )

  return (
    <>

      <TotalBox>
        <TotalLine>
          {isActiveButCanceled
            ? i18n("Expires: {{date}}", {
              date: new Date(currentPeriodEndsAt).toLocaleDateString(getLocale()),
            })
            : (
              [ 'changed' ].includes(subscriptionSelectionStatus)
                ? i18nReact("Updated Total: {{amount_per_period}}", { amount_per_period })
                : i18nReact("Total: {{amount_per_period}}", { amount_per_period })
            )
          }
        </TotalLine>
        {[ 'changed', 'new' ].includes(subscriptionSelectionStatus) && !!relevantStripeCurrentSale.id &&
          <Coupon>
            <CouponIconContainer>
              <LocalOfferIcon />
            </CouponIconContainer>
            <CouponDetails>
              <CouponName>
                {relevantStripeCurrentSale.name}
              </CouponName>
              <CouponDiscount>
                {getDiscountText({ ...relevantStripeCurrentSale, term })}
              </CouponDiscount>
            </CouponDetails>
          </Coupon>
        }
        {[ 'new' ].includes(subscriptionSelectionStatus) &&
          <CheckoutButtonContainer>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              onClick={goCheckout}
              disabled={!online}
            >
              {i18n("Checkout")}
            </Button>
          </CheckoutButtonContainer>
        }
        {[ 'changed' ].includes(subscriptionSelectionStatus) &&
          <CheckoutButtonContainer>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              onClick={updateSubscription}
              disabled={!online || !readyToUpdate}
            >
              {i18n("Update subscription")}
              {!readyToUpdate && <Loading size={20} bgOpacity={0} />}
            </Button>
            <CancelButton
              variant="contained"
              disableElevation
              onClick={cancelUpdate}
              disabled={!online}
            >
              {i18n("Cancel")}
            </CancelButton>
          </CheckoutButtonContainer>
        }
        {[ 'unchanged' ].includes(subscriptionSelectionStatus) && !isActiveButCanceled &&
          <>
            {!!(networkStatusStripeSubscriptionNextInvoice < 7 || stripeSubscriptionNextInvoice) &&
              <NextChargeMessage>
                {i18nReact("Next charge: ${{amount}} on {{date}}", (  // eslint-disable-line no-template-curly-in-string
                  networkStatusStripeSubscriptionNextInvoice >= 7
                    ? {
                      amount: stripeSubscriptionNextInvoice.total.toFixed(2),
                      date: new Date(stripeSubscriptionNextInvoice.attemptAt).toLocaleDateString(getLocale()),
                    }
                    : {
                      amount: <Faded>{amount}</Faded>,
                      date: new Date(currentPeriodEndsAt).toLocaleDateString(getLocale()),
                    }
                ))}
              </NextChargeMessage>
            }
            {showApplyButton &&
              <>
                <SaleImg
                  src={(relevantStripeCurrentSale.metadata || {}).imageUrl}
                  className="dark-mode-exempt"
                />
                <CheckoutButtonContainer>
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={updateSubscription}
                    disabled={!online}
                  >
                    {i18n("Apply sale discount to next charge")}
                  </Button>
                </CheckoutButtonContainer>
              </>
            }
            {!showApplyButton && currentStripeCurrentCoupon &&
              <Coupon>
                <CouponIconContainer>
                  <LocalOfferIcon />
                </CouponIconContainer>
                <CouponDetails>
                  <CouponName>
                    {currentStripeCurrentCoupon.name}
                  </CouponName>
                  <CouponDiscount>
                    {getDiscountText({ ...currentStripeCurrentCoupon, term, doRemainingPeriod: true })}
                  </CouponDiscount>
                </CouponDetails>
              </Coupon>
            }
            <NextChargeMessage>
              <Button
                variant="contained"
                disableElevation
                size="small"
                onClick={goToBilling}
              >
                {i18n("Cancel your subscription")}
              </Button>
            </NextChargeMessage>
          </>
        }
        {isActiveButCanceled &&
          <CheckoutButtonContainer>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              onClick={goToBilling}
              disabled={!online}
            >
              {i18n("Renew your subscription")}
            </Button>
          </CheckoutButtonContainer>
        }
      </TotalBox>
      {[ 'unchanged' ].includes(subscriptionSelectionStatus) &&
        <>
          <UpdateSubscriptionMessage>
            {isActiveButCanceled
              ? i18n("To change your plan or donation amount, you first must renew your subscription.")
              : i18n("Click the plan or donation line above to make changes to your subscription.")
            }
          </UpdateSubscriptionMessage>
          {[ 'unchanged' ].includes(subscriptionSelectionStatus) &&
            <CheckoutButtonContainer>
              <Button
                variant="contained"
                color={(isActiveButCanceled || showApplyButton) ? "default" : "primary"}
                disableElevation
                onClick={goToBilling}
                disabled={!online}
              >
                {i18n("Go to billing")}
              </Button>
            </CheckoutButtonContainer>
          }
        </>
      }
      {proratingNeeded &&
        <ProratedMessage>
          {readyToUpdate && totalDueNow > 0 && !relevantStripeCurrentSale.id &&
            i18nReact("You will be charged ${{amount}} today and then {{amount_per_period}} beginning {{date}}.", {  // eslint-disable-line no-template-curly-in-string
              amount: totalDueNow.toFixed(2),
              amount_per_period: amountPerPeriod,
              date: new Date(nextChargeAt).toLocaleDateString(getLocale()),
            })
          }
          {readyToUpdate && legacyCredit > 0 && !!relevantStripeCurrentSale.id &&
            i18n("Your charge today will be appropriately adjusted at checkout.")
          }
          {readyToUpdate && totalDueNow === 0 && (stripeSubscriptionChangePreview || {}).nextChargeAmount &&
            i18n("Your next charge will be ${{amount}} on {{date}}.", {  // eslint-disable-line no-template-curly-in-string
              amount: stripeSubscriptionChangePreview.nextChargeAmount.toFixed(2),
              date: new Date(nextChargeAt).toLocaleDateString(getLocale()),
            })
          }
          {readyToUpdate && (stripeSubscriptionChangePreview || {}).totalDueNow === 0 &&
            i18n("You will not be charged today.")
          }
          {readyToUpdate && totalDueNow < 0 &&
            i18nReact("You will not be charged today. Instead, you will be credited ${{amount}} and only begin being charged once that balance is exhausted.", {  // eslint-disable-line no-template-curly-in-string
              amount: (totalDueNow * -1).toFixed(2),
              amount_per_period: amountPerPeriod,
              date: new Date(nextChargeAt).toLocaleDateString(getLocale()),
            })
          }
        </ProratedMessage>
      }
      {!online &&
        <OfflineMessage>
          {i18n("You are currently offline.")}
        </OfflineMessage>
      }
      {subscriptionSelectionStatus === 'unchanged' &&
        <CreditMessage>
          {i18nReact("Click {{here}} to learn about all you can do with {{subscription_name}}.", {
            here: <NavLink exact to={getYourSubPathname(plan)}>{i18n("here")}</NavLink>,
            subscription_name: (
              <SingleLinePlanImg
                src={{
                  MY: `my_biblearc_study_bible_1.svg`,
                  TOOLS: `biblearc_tools_1.svg`,
                  EQUIP: `biblearc_equip_1.svg`,
                }[plan]}
              />
            )
          })}
        </CreditMessage>
      }

      {submitting && <Loading />}

      {errorDialog}

    </>
  )
}


export default memo(ReviewBeforeCheckout)