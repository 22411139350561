// i18n-default-category:"church-bible"

import { memo, useState, useCallback } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import SearchIcon from '@material-ui/icons/Search'
import { useApolloClient } from '@apollo/client'

import useRefState from '../../../hooks/useRefState'
import useSimpleToggle from '../../../hooks/useSimpleToggle'
import { preventDefaultEvent } from '../../../utils/misc'
import i18nReact from '../../../utils/i18nReact'

import FadedLoading from '../../common/FadedLoading'
import NavLinkOrAWithDisable from '../../common/NavLinkOrAWithDisable'

import channelsQuery from '../../../graphql/queries/channels'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: -10px 0 -50px;
`

const StyledTextField = styled(TextField)`
  margin-top: 15px;

  .MuiInputBase-root {
    padding-right: 5px;
  }
`

const ChannelsSearchResults = styled.div`
  position: relative;
  flex: 1;
  overflow: auto;
  margin: 20px 0;
  min-height: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Error = styled.div`
  color: ${({ theme }) => theme.palette.tertiary.main};
  text-align: center;
  padding: 30px;
`

const None = styled.div`
  font-weight: 500;
  padding: 20px 0 0;
`

const CreateOne = styled.div`
  padding: 10px 0 30px;
  font-weight: 300;
  font-style: italic;
`

const LearnToCreate = styled.div`
  padding: 10px 0 30px;
  font-weight: 300;
`

const ResultSection = styled.div`
  text-align: left;
  width: 360px;
  max-width: calc(100vw - 40px);
`

const Image = styled.img`
  width: 32px;
  height: 32px;
  margin: 0 9px 0 0;
  object-fit: cover;
  border-radius: 5px;
`

const ItemContent = styled.span`
  font-weight: 300;
  white-space: nowrap;
  display: flex;
  align-items: center;  
`

const ItemText = styled.span`
  flex: 1;
  font-size: 19px;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Name = styled.span`
  display: inline-block;
  font-weight: 500;
`

const ChurchLine = styled.div`
  padding: 15px 15px;
  cursor: pointer;
  border-radius: 5px;

  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.palette.grey[100]};
    }
  }
`


const NameAndLocation = styled.div`
  font-size: 12px;
  font-weight: 300;
  color: ${({ theme }) => theme.palette.grey[600]};
  margin: -5px 0 0 42px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const FullName = styled.span`
  color: black;
`

const Location = styled.span`
`

const StyledButton = styled(Button)`
  margin-top: 5px;
  align-self: center;
`

const ChurchBibleFinder = ({
  onSelect,
  learnToCreateLink,
  textFieldProps={},
  className,
}) => {

  const [ search, setSearch, getSearch ] = useRefState(``)
  const [ searching, setSearching ] = useState(false)
  const [ error, setError ] = useState()
  const [ churchBibles, setChurchBibles ] = useState()
  const [ showMax, toggleShowMax ] = useSimpleToggle()

  const client = useApolloClient()

  const onChangeSearch = useCallback(({ target }) => setSearch(target.value), [ setSearch ])

  const goSearch = useCallback(
    async () => {

      setError()

      let query = getSearch().replace(/  +/g, ` `).trim()

      if(!query) {
        setChurchBibles()
        return
      }

      setSearching(true)

      try {

        const { data: { channels } } = await client.query({
          query: channelsQuery,
          variables: {
            query,
            limit: 50,
          },
        })

        setChurchBibles(channels.channels)
        toggleShowMax({ force: false })

      } catch(err) {
        console.error(err)
        setChurchBibles()
        toggleShowMax({ force: false })
        setError(`Channels search error`)
        setSearching(false)
      }

      setSearching(false)

    },
    [ getSearch, client, toggleShowMax ],
  )

  const onKeyDownSearch = useCallback(
    async event => {

      if(event.key === `Enter`) {
        goSearch()
      }

    },
    [ goSearch ],
  )

  return (
    <Container className={className}>

      <StyledTextField
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={goSearch}
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        label={i18n("Search by name or location")}
        fullWidth
        variant="outlined"
        value={search}
        onChange={onChangeSearch}
        onKeyDown={onKeyDownSearch}
        {...textFieldProps}
      />

      <ChannelsSearchResults
        className="ChurchBibleFinder-ChannelsSearchResults"
      >

        {!!error &&
          <Error>
            {error}
          </Error>
        }

        {!!churchBibles && churchBibles.length === 0 &&
          <>
            <None>
              {i18n("None found.")}
            </None>
            {!learnToCreateLink &&
              <CreateOne>
                {i18n("Create one below!")}
              </CreateOne>
            }
            {learnToCreateLink &&
              <LearnToCreate>
                <NavLinkOrAWithDisable
                  to="/about-church-bibles"
                >
                  {i18n("Learn how to create one")}
                </NavLinkOrAWithDisable>
              </LearnToCreate>
            }
          </>
        }

        {(churchBibles || []).length > 0 &&
          <ResultSection
            className="ChurchBibleFinder-ResultSection"
          >

            {churchBibles.slice(0, showMax ? Infinity : 10).map(churchBible => {
              const { id, name, shortName, location, updatedAt } = churchBible
              return (
                <NavLinkOrAWithDisable
                  key={id}
                  to={`/church/${id}`}
                >
                  <ChurchLine
                    className="ChurchBibleFinder-ChurchLine"
                    onClick={
                      onSelect
                        ? (
                          event => {
                            preventDefaultEvent(event)
                            onSelect(churchBible)
                          }
                        )
                        : null
                    }
                    data-id={id}
                  >

                    <ItemContent>
                      <Image
                        src={`${process.env.REACT_APP_ASSETS_URI}${id}-favicon_192-${updatedAt}.png`}
                        className="dark-mode-exempt ChurchBibleFinder-Image"
                      />
                      <ItemText
                        className="ChurchBibleFinder-ItemText"
                      >
                        {i18nReact("The {{short_name}} Study Bible", {
                          short_name: (
                            <Name>
                              {shortName}
                            </Name>
                          ),
                        })}
                      </ItemText>
                    </ItemContent>

                    <NameAndLocation
                      className="ChurchBibleFinder-NameAndLocation"
                    >

                      <FullName>
                        {name}
                      </FullName>

                      {` // `}

                      <Location>
                        {location}
                      </Location>

                    </NameAndLocation>

                  </ChurchLine>
                </NavLinkOrAWithDisable>
              )
            })}

          </ResultSection>
        }

        {!showMax && (churchBibles || []).length > 10 &&
          <StyledButton
            onClick={toggleShowMax}
            variant="contained"
            disableElevation
            size="small"
          >
            {i18n("Load more")}
          </StyledButton>
        }

        {searching && <FadedLoading size={30} />}

      </ChannelsSearchResults>

    </Container>
  )
}

export default memo(ChurchBibleFinder)