// i18n-default-category:"subscriptions"

import { memo, useState, useCallback } from 'react'
import { i18n, getLocale } from 'inline-i18n'
import styled from 'styled-components'
import { useMutation } from '@apollo/client'
import Button from '@material-ui/core/Button'

import { getSubscriptionTypeLanguage, getSubscriptionPlanImageSrc, getSubscriptionTermLanguage, getDiscountText } from '../../utils/misc'
import useMutationContext from '../../hooks/useMutationContext'
import i18nReact from '../../utils/i18nReact'

import ConfirmDialog from './ConfirmDialog'

import cancelSubscriptionMutation from '../../graphql/mutations/cancelSubscription'

const Container = styled.div`
  padding: ${({ $viewingAUserAsAdmin }) => $viewingAUserAsAdmin ? `12px 0 5px` : `5px 0 0`};
  line-height: 1.3;
`

const SubStatus = styled.div`
  font-size: 12px;
  margin: 5px 0 0;
`

const SubStatusStatus = styled.span`
  display: inline-block;
  padding: 1px 4px;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: -3px;
  background: ${({ theme, $isActive }) => theme.palette.grey[$isActive ? 900 : 500]};
  color: white;
  border-radius: 3px;
`

const SubStatusPrecise = styled.span`
  display: inline-block;
  font-weight: 200;
  margin: 0 10px;
`

const SubPlanImage = styled.img`
  height: 20px;
  margin: 3px -5px -10px -5px;
`

const SubType = styled.div`
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
`

const GroupName = styled.div`
  font-size: 12px;
`

const Created = styled.div`
  font-weight: 300;
  font-size: 11px;
`

const SubTerm = styled.div`
  font-weight: 600;
  font-size: 11px;
`

const SubOngoingCoupon = styled.div`
  font-size: 11px;
  color: ${({ theme }) => theme.palette.tertiary.dark};
`

const SubDonation = styled.div`
  font-size: 11px;
`

const SubCanceled = styled.div`
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.tertiary.main};
`

const SubCurrentPeriodEnds = styled.div`
  font-weight: 300;
  font-size: 11px;
`

const CancelSubButton = styled(Button)`
  margin-top: 3px;
  font-size: 11px;
  padding: 5px 10px;
  line-height: 1.5;
`

const SubscriptionInfoLine = ({
  id,
  type,
  plan,
  term,
  status,
  stripeCurrentCoupon,
  additionalDonationAmount,
  cancelAtPeriodEnd,
  currentPeriodEndsAt,
  createdAt,
  groupSubscription,
  isActive,
  viewingAUserAsAdmin,
  setSelectedUser,
}) => {
  
  const [ showConfirmCancel, setShowConfirmCancel ] = useState(false)
  const [ canceling, setCanceling ] = useState(false)
  
  const [ cancelSubscription ] = useMutation(cancelSubscriptionMutation)
  const context = useMutationContext()

  const goCancel = useCallback(
    async () => {

      setCanceling(true)

      const { data: { cancelSubscription: updatedUser } } = await cancelSubscription({
        variables: {
          id,
        },
        context,
      })

      setCanceling(false)
      setShowConfirmCancel(false)
      setSelectedUser(updatedUser)

    },
    [ cancelSubscription, id, context, setSelectedUser ],
  )

  const date = new Date(currentPeriodEndsAt).toLocaleDateString(getLocale())
  const expired = currentPeriodEndsAt < Date.now()

  return (
    <Container $viewingAUserAsAdmin={viewingAUserAsAdmin}>

      {viewingAUserAsAdmin &&
        <SubStatus>
          <SubStatusStatus $isActive={isActive}>
            {
              isActive
                ? i18n("Active", "", "admin")
                : (
                  expired
                    ? i18n("Expired", "", "admin")
                    : i18n("Canceled", "", "admin")
                )
            }
          </SubStatusStatus>
          <SubStatusPrecise>
            {status}
          </SubStatusPrecise>
        </SubStatus>
      }

      <SubPlanImage src={getSubscriptionPlanImageSrc(plan)} />

      <SubType>
        {getSubscriptionTypeLanguage(type)}
      </SubType>

      {type === `GROUP-MEMBER` && !!groupSubscription &&
        <GroupName>
          {groupSubscription.name}
        </GroupName>
      }

      <Created>
        {i18n("Began: {{date}}", {
          date: new Date(createdAt).toLocaleDateString(getLocale()),
        })}
      </Created>

      {type === `STRIPE` &&
        <SubTerm>
          {getSubscriptionTermLanguage(term)}
        </SubTerm>
      }

      {stripeCurrentCoupon && !(type === `STRIPE` && cancelAtPeriodEnd) &&
        <SubOngoingCoupon>
          {getDiscountText({ ...stripeCurrentCoupon, term, doRemainingPeriod: true })}
        </SubOngoingCoupon>
      }

      {parseInt(additionalDonationAmount || 0, 10) !== 0 &&
        <SubDonation>
          {i18n("Additional donation amount: ${{amount}}", { amount: additionalDonationAmount.toFixed(2) })}  { /* eslint-disable-line no-template-curly-in-string */ }
        </SubDonation>
      }

      {type === `STRIPE` && !cancelAtPeriodEnd &&
        <SubCurrentPeriodEnds>
          {i18nReact("Next renewal: {{date}}", { date })}
        </SubCurrentPeriodEnds>
      }

      {type === `STRIPE` && !!cancelAtPeriodEnd &&
        <SubCanceled>
          {i18n("Canceled")}
        </SubCanceled>
      }

      {!!(type !== `STRIPE` || cancelAtPeriodEnd) &&
        <SubCurrentPeriodEnds>
          {expired
            ? i18nReact("Expired: {{date}}", { date })
            : i18nReact("Expires: {{date}}", { date })
          }
        </SubCurrentPeriodEnds>
      }

      {viewingAUserAsAdmin && isActive && !(type === `STRIPE` && cancelAtPeriodEnd) &&
        <div>
          <CancelSubButton
            variant="contained"
            disableElevation
            onClick={() => setShowConfirmCancel(true)}
            size="small"
            color="primary"
          >
            {i18n("Cancel this subscription", "", "admin")}
          </CancelSubButton>
        </div>
      }

      <ConfirmDialog
        open={showConfirmCancel}
        onClose={() => setShowConfirmCancel(false)}
        onConfirm={goCancel}
        explanation={i18n("Are you sure you want to cancel this {{type}}?", {
          type: getSubscriptionTypeLanguage(type).toUpperCase(),
        })}
        loading={canceling}
      />

    </Container>
  )
}

export default memo(SubscriptionInfoLine)
