import { useContext } from 'react'

import useDataQuery from './useDataQuery'
import useEqualObjsMemo from './useEqualObjsMemo'
import { LoggedInUserContext } from '../context/LoggedInUser'

import userQuery from '../graphql/queries/user'

const MAX_NUMBER_OF_USERS = 20

const useUsers = ({
  userIds,
 }) => {

  const loggedInUser = useContext(LoggedInUserContext)

  const uniqueUserIds = [ ...new Set(userIds) ].filter(userId => userId !== loggedInUser.id)

  const queryInfo = Array(MAX_NUMBER_OF_USERS).fill().map((x, idx) => ({
    userQuery,
    variables: {
      id: uniqueUserIds[idx],
    },
  }))

  const getValue = (queryValue, idx) => queryValue.user || { id: uniqueUserIds[idx] }

  let idx = -1
  let usersById = {
    [uniqueUserIds[++idx]]: getValue(useDataQuery(queryInfo[idx]), idx),
    [uniqueUserIds[++idx]]: getValue(useDataQuery(queryInfo[idx]), idx),
    [uniqueUserIds[++idx]]: getValue(useDataQuery(queryInfo[idx]), idx),
    [uniqueUserIds[++idx]]: getValue(useDataQuery(queryInfo[idx]), idx),
    [uniqueUserIds[++idx]]: getValue(useDataQuery(queryInfo[idx]), idx),
    [uniqueUserIds[++idx]]: getValue(useDataQuery(queryInfo[idx]), idx),
    [uniqueUserIds[++idx]]: getValue(useDataQuery(queryInfo[idx]), idx),
    [uniqueUserIds[++idx]]: getValue(useDataQuery(queryInfo[idx]), idx),
    [uniqueUserIds[++idx]]: getValue(useDataQuery(queryInfo[idx]), idx),
    [uniqueUserIds[++idx]]: getValue(useDataQuery(queryInfo[idx]), idx),
    [uniqueUserIds[++idx]]: getValue(useDataQuery(queryInfo[idx]), idx),
    [uniqueUserIds[++idx]]: getValue(useDataQuery(queryInfo[idx]), idx),
    [uniqueUserIds[++idx]]: getValue(useDataQuery(queryInfo[idx]), idx),
    [uniqueUserIds[++idx]]: getValue(useDataQuery(queryInfo[idx]), idx),
    [uniqueUserIds[++idx]]: getValue(useDataQuery(queryInfo[idx]), idx),
    [uniqueUserIds[++idx]]: getValue(useDataQuery(queryInfo[idx]), idx),
    [uniqueUserIds[++idx]]: getValue(useDataQuery(queryInfo[idx]), idx),
    [uniqueUserIds[++idx]]: getValue(useDataQuery(queryInfo[idx]), idx),
    [uniqueUserIds[++idx]]: getValue(useDataQuery(queryInfo[idx]), idx),
    [uniqueUserIds[++idx]]: getValue(useDataQuery(queryInfo[idx]), idx),
    [(loggedInUser || {}).id]: loggedInUser
  }

  delete usersById[undefined]
  usersById = useEqualObjsMemo(usersById)

  return usersById

}

export default useUsers