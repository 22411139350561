import { memo } from 'react'
import styled from 'styled-components'
// import { i18n } from 'inline-i18n'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import useAccountSetting from '../../hooks/useAccountSetting'
import { cloneObj, preventDefaultEvent } from '../../utils/misc'
import i18nReact from '../../utils/i18nReact'

const Container = styled.div`
  position: relative;
`

const StyledListItem = styled(ListItem)`
  position: relative;

  @media (hover: hover) {
    &:hover .MuiButtonBase-root {
      display: block;
    }
  }
`

const StyledIconButton = styled(IconButton)`
  position: absolute;
  right: 8px;
  top: 8px;
  padding: 8px;
  background: white;

  @media (hover: hover) {
    display: none;

    &:hover {
      background: ${({ theme }) => theme.palette.grey[300]};
    }
  }

  .MuiSvgIcon-root {
    font-size: 16px;
  }
`

const Image = styled.img`
  width: 26px;
  height: 26px;
  margin: -5px 7px -5px 0;
  object-fit: cover;
  border-radius: 5px;
`

const ItemContent = styled.span`
  font-weight: 300;
  white-space: nowrap;
  display: flex;
  align-items: center;  
`

const ItemText = styled.span`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Name = styled.span`
  display: inline-block;
  font-weight: 500;
`

const RecentChurchBibles = ({
  toggleDrawerOpen,
  MenuItemNavLink,
  fixedChurchBible,
}) => {

  let [ churchBibles, setChurchBibles ] = useAccountSetting(`church-bibles`, [])

  if(fixedChurchBible) {
    churchBibles = [ fixedChurchBible ]
  }

  return (
    <Container>

      {churchBibles.map(({ id, shortName, updatedAt }, idx) => (
        <MenuItemNavLink
          key={idx}
          exact
          to={`/church/${id}`}
        >
          <StyledListItem
            button
            onClick={toggleDrawerOpen}
          >
            <ListItemText
              primary={
                <ItemContent>
                  <Image
                    src={`${process.env.REACT_APP_ASSETS_URI}${id}-favicon_192-${updatedAt}.png`}
                    className="dark-mode-exempt"
                  />
                  <ItemText>
                    {i18nReact("The {{short_name}} Study Bible", {
                      short_name: (
                        <Name>
                          {shortName}
                        </Name>
                      ),
                    })}
                  </ItemText>
                </ItemContent>
              }
            />
            {!fixedChurchBible &&
              <StyledIconButton
                onClick={event => {
                  preventDefaultEvent(event)
                  const newChurchBibles = cloneObj(churchBibles)
                  newChurchBibles.splice(idx, 1)
                  setChurchBibles(newChurchBibles)
                }}
              >
                <CloseIcon />
              </StyledIconButton>
            }
          </StyledListItem>
        </MenuItemNavLink>
      ))}

    </Container>
  )
}

export default memo(RecentChurchBibles)