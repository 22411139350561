import { memo, useState, useCallback, useMemo } from 'react'
import { i18n } from 'inline-i18n'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import CheckIcon from '@material-ui/icons/Check'
import styled from 'styled-components'
import { getUsfmBibleBookAbbr } from '@bibletags/bibletags-ui-helper'
import { getLocFromRef, getNextTranslationRef } from '@bibletags/bibletags-versification'

import useInstanceValue from '../../hooks/useInstanceValue'
import useVersionInfos from '../../hooks/useVersionInfos'
import useEffectAsync from '../../hooks/useEffectAsync'
import { KJV_VERSION } from '../../utils/constants'
import i18nReact from '../../utils/i18nReact'

import ConfirmDialog from './ConfirmDialog'
import NavLinkOrAWithDisable from './NavLinkOrAWithDisable'
import PassageRef from './PassageRef'

const Container = styled.div`
  width: 440px;
  max-width: 100%;
  min-height: 198px;
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

const Title = styled.div`
  margin-right: 20px;
`

const StyledNavLinkOrAWithDisable = styled(NavLinkOrAWithDisable)`
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 14px;

  &:hover {
    text-decoration: underline;
  }
`

const StyledOpenInNewIcon = styled(OpenInNewIcon)`
  height: 12px;
  width: 12px;
  margin-left: 3px;
`

const AbbrTextField = styled(TextField)`
  margin-bottom: 20px;
  width: 200px;
  display: block;
  margin-right: auto;

  .MuiInputBase-input {
    font-weight: bold;
  }
`

const BibleTextTextField = styled(TextField)`
  margin-bottom: 20px;

  .MuiInputBase-input {
    font-size: 13px;
  }
`

const StyledCheckIcon = styled(CheckIcon)`
  height: 15px;
  width: 15px;
  margin: -3px 2px -3px -2px;
`

const LookingForContainer = styled.div`
  margin-bottom: 20px;
`

const YesAndNoContainer = styled.div`
  margin-top: 10px;
  display: flex;
`

const YesOrNoButton = styled(Button)`
  margin-right: 7px;
`

const ResponseToAnswer = styled.div`
  margin: 5px 0 10px;
  font-style: italic;
`

const Done = styled.div`
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .MuiSvgIcon-root {
    margin-right: 3px;
    height: 20px;
    width: 20px;
  }
`

const HowToAddOthers = styled.div`
  margin: 5px 0 10px;
  color: ${({ theme }) => theme.palette.grey[600]};
`

const AddVersionButton = styled(Button)`
  text-transform: none;
`

const PasteInExternalTextDialog = ({
  versionId,
  refs,
  onConfirm,
  ...otherProps
}) => {

  const [ versionAbbr, setVersionAbbr ] = useState(``)
  const [ pastedInText, setPastedInText ] = useState(``)
  const [ pastedInVerses, setPastedInVerses ] = useState()
  const [ errorMessage, setErrorMessage ] = useState(null)
  const [ showErrorMessage, setShowErrorMessage ] = useState(false)
  const [ lookingForStep, setLookingForStep ] = useState(`are-you`)

  const getPastedInVerses = useInstanceValue(pastedInVerses)
  const getVersionAbbr = useInstanceValue(versionAbbr)

  const { count, versions=[], selectedVersionInfos, updateSelectedVersionIds } = useVersionInfos({
    skip: !versionAbbr,
    searchText: `abbr:${versionAbbr}`,
    index: 1,
  })
  const matchingVersionId = (versions[0] || {}).id

  const onVersionAbbrChange = useCallback(
    ({ target }) => {
      setVersionAbbr(target.value.replace(/[^-0-9a-z]/gi, '').toUpperCase().slice(0, 9))
      setLookingForStep(`are-you`)
    },
    [],
  )

  const passage = useMemo(() => <PassageRef refs={refs} />, [ refs ])

  const onPastedInTextChange = useCallback(
    ({ target }) => {
      setPastedInText(target.value)
      setShowErrorMessage(false)

      const pieces = target.value.split(/([0-9]+)(?![,])/g)

      let newPastedInVerses = {}
      let nextExpectedRef = refs[0]
      let lastExpectedLoc = getLocFromRef(refs.at(-1))
      for(let idx=0; pieces[idx] !== undefined; idx++) {
        if(pieces[idx] === `${nextExpectedRef.verse}`) {

          const ref = nextExpectedRef
          nextExpectedRef = getNextTranslationRef({ ref, info: KJV_VERSION }) || {}

          let content = ``
          while(![ undefined, `${nextExpectedRef.verse}` ].includes(pieces[idx + 1])) {
            content += pieces[++idx]
          }
          newPastedInVerses[getLocFromRef(ref)] = content.replace(/\\/g, '').trim()

          if(getLocFromRef(nextExpectedRef) > lastExpectedLoc) break

        }
      }
      const nextExpectedLoc = getLocFromRef(nextExpectedRef)
      if(
        nextExpectedLoc <= lastExpectedLoc
        && nextExpectedLoc !== `01001001`  // in case the passage range is to the end of the Bible
      ) {
        newPastedInVerses = undefined
      }

      setPastedInVerses(newPastedInVerses)
      setErrorMessage(
        newPastedInVerses
          ? (
            <>
              <StyledCheckIcon />
              {i18nReact("{{passage}} detected.", { passage })}
            </>
          )
          : (
            target.value.trim() === ``
              ? null
              : i18nReact("Be sure to include all of {{passage}}.", { passage })
          )
      )
    },
    [ refs, passage ],
  )

  const onPastedInTextBlur = useCallback(() => setShowErrorMessage(!getPastedInVerses()), [ getPastedInVerses ])

  const goOnConfirm = useCallback(
    () => {
      if(lookingForStep === `how-to-add-others`) {
        onConfirm({
          versionId: matchingVersionId,
          refs,
        })
      } else {
        onConfirm({
          versionId: `external_${getVersionAbbr()}`,
          refs,
          pastedInVerses: getPastedInVerses(),
        })
      }
    },
    [ matchingVersionId, lookingForStep, onConfirm, refs, getPastedInVerses, getVersionAbbr ],
  )

  const addVersion = useCallback(
    () => {
      updateSelectedVersionIds([
        ...selectedVersionInfos.map(({ id }) => id),
        matchingVersionId,
      ])
      setLookingForStep(`how-to-add-others`)
    },
    [ matchingVersionId, selectedVersionInfos, updateSelectedVersionIds ],
  )

  useEffectAsync(
    () => {
      setPastedInText(``)
      setShowErrorMessage(false)
      setPastedInVerses()
      setErrorMessage(null)
    },
    [ refs ],
  )

  const bibleComLink = refs ? `https://www.bible.com/bible/1/${getUsfmBibleBookAbbr(refs[0].bookId)}.${refs[0].chapter}.KJV` : ``
  const versionAbbrAlreadyInUse = (
    lookingForStep !== `how-to-add-others`
    && selectedVersionInfos.some(({ version: { abbr }={} }) => abbr === versionAbbr)
  )

  return (
    <ConfirmDialog
      {...otherProps}
      onConfirm={goOnConfirm}
      title={
        <TitleContainer>
          <Title>
            {i18n("Paste in a Bible Text")}
          </Title>
          <StyledNavLinkOrAWithDisable
            to={bibleComLink}
            keepLinkStyle
          >
            {i18n("Copy from YouVersion")}
            <StyledOpenInNewIcon />
          </StyledNavLinkOrAWithDisable>
        </TitleContainer>
      }
      explanation={
        <Container>

          {lookingForStep !== `how-to-add-others` &&
            <AbbrTextField
              label={i18n("Version Abbreviation")}
              variant="outlined"
              value={versionAbbr}
              onChange={onVersionAbbrChange}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                spellCheck: "false",
                "data-gramm": "false",
              }}
              required
              autoComplete="off"
            />
          }

          {count >= 1 &&
            <LookingForContainer>
              {versionAbbrAlreadyInUse &&
                <ResponseToAnswer>
                  {i18n("That version abbreviation is already in use.")}
                </ResponseToAnswer>
              }
              {!versionAbbrAlreadyInUse &&
                <>
                  {lookingForStep !== `how-to-add-others` &&
                    i18nReact("Are you looking for the {{text}}?", {
                      text: (
                        <b>
                          {versions[0].name}
                        </b>
                      ),
                    })
                  }
                  {lookingForStep === `are-you` &&
                    <YesAndNoContainer>
                      <YesOrNoButton
                        onClick={() => setLookingForStep(`we-have-it`)}
                        variant="outlined"
                        color="primary"
                      >
                        {i18n("Yes")}
                      </YesOrNoButton>
                      <YesOrNoButton
                        onClick={() => setLookingForStep(`change-abbr`)}
                        variant="outlined"
                        color="primary"
                      >
                        {i18n("No")}
                      </YesOrNoButton>
                    </YesAndNoContainer>
                  }
                  {lookingForStep === `how-to-add-others` &&
                    <>
                      <Done>
                        <CheckIcon />
                        {i18nReact("{{text}} added", {
                          text: versions[0].name,
                        })}
                      </Done>
                      <HowToAddOthers>
                        {i18n("Note: To add, remove, and reorder Bible versions, open the main menu and select Settings > Bible Versions.")}
                      </HowToAddOthers>
                    </>
                  }
                  {lookingForStep === `we-have-it` &&
                    <>
                      <ResponseToAnswer>
                        {i18n("Great! We have that version.")}
                      </ResponseToAnswer>
                      <AddVersionButton
                        onClick={addVersion}
                        variant="contained"
                        disableElevation
                        color="primary"
                      >
                        {i18n("Add the {{version_abbr}} to My Bible Versions", {
                          version_abbr: versionAbbr,
                        })}
                      </AddVersionButton>
                    </>
                  }
                  {lookingForStep === `change-abbr` &&
                    <ResponseToAnswer>
                      {i18n("In that case, you will need to use a different version abbreviation.")}
                    </ResponseToAnswer>
                  }
                </>
              }
            </LookingForContainer>
          }

          {(count || 0) < 1 &&
            <BibleTextTextField
              label={i18n("Bible Text")}
              placeholder={i18n("E.g. 1 In the beginning God created the heavens and the earth. 2 And the earth was without form and void...")}
              error={showErrorMessage && !!errorMessage}
              helperText={errorMessage || i18nReact("Paste or type in {{passage}}, including verse numbers.", { passage })}
              variant="outlined"
              size="small"
              value={pastedInText}
              onChange={onPastedInTextChange}
              onBlur={onPastedInTextBlur}
              multiline
              fullWidth
              minRows={3}
              maxRows={10}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                spellCheck: "false",
                "data-gramm": "false",
              }}
              required
              autoComplete="off"
            />
          }

        </Container>
      }
      confirmButtonLabel={i18n("Continue")}
      disabled={
        lookingForStep !== `how-to-add-others`
        && (
          versionAbbr.length < 2
          || !pastedInVerses
          || count !== 0
        )
      }
    />
  )
}

export default memo(PasteInExternalTextDialog)