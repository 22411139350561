// i18n-default-category:"original-languages"

import { memo, useCallback, useState } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import i18nReact from '../../../utils/i18nReact'

import Header from "../../common/Header"

const Container = styled.div`
  flex: 1;
  overflow: auto;
  padding: 10px 20px 20px;
`

const Content = styled.div`
  max-width: 700px;
  margin: 10px auto 20px;
  font-size: 13px;
`

const TabContent = styled.div`
  padding: 15px 0;
`

const Category = styled.div`
  margin: 10px 0;
`

const CategoryHeading = styled.div`
  font-size: 18px;
  font-weight: 900;
  margin-bottom: 5px;
`

const CategoryExplanation = styled.div`
  margin: 0 0 7px;
`

const DetailContainer = styled.div`
`

const DetailWithoutMargin = styled.span`
  display: inline-block;
  background-color: ${({ theme }) => theme.palette.grey[100]};
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 3px;
  padding: 1px 6px;
  color: ${({ theme }) => theme.palette.grey[900]};
`

const Detail = styled(DetailWithoutMargin)`
  margin: 0 5px 5px 0;
`

const Hash = styled.span`
  color: ${({ theme }) => theme.palette.grey[500]};
`

const DetailExplanation = styled.span`
  display: inline-block;
  direction: rtl;
`

const Subcategory = styled.div`
  padding: 2px 0 6px;
`

const SubcategoryHeading = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
`

const Examples = styled.div`
  padding: 5px 12px 0;
  border-left: 1px solid ${({ theme }) => theme.palette.divider};
`

const ExamplesHeading = styled.div`
  color: ${({ theme }) => theme.palette.grey[700]};
  font-size: 12px;
  margin-bottom: 7px;
`

const GrammarSearchKey = ({ ...props }) => {

  const [ tabIdx, setTabIdx ] = useState(0)

  const handleTabChange = useCallback(
    (event, newTabIdx) => setTabIdx(newTabIdx),
    [],
  )

  return (
    <>

      <Header {...props} />

      <Container>
        <Content>

          <Tabs
            value={tabIdx}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label={i18n("Hebrew and Aramaic")} />
            <Tab label={i18n("Greek")} />
          </Tabs>

          {tabIdx === 0 &&
            <TabContent>

              <Category>
                <CategoryHeading>{i18n("Part of Speech")}</CategoryHeading>

                <DetailContainer>
                  <Detail><Hash>#</Hash>{i18n("noun")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("pronoun")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("adjective")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("verb")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("adverb")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("preposition")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("conjunction")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("particle")}</Detail>
                </DetailContainer>
              </Category>

              <Subcategory>
                <SubcategoryHeading>{i18n("Pronoun Type")}</SubcategoryHeading>

                <DetailContainer>
                  <Detail><Hash>#</Hash>{i18n("demonstrative")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("indefinite")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("personal")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("interrogative")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("relative")}</Detail>
                </DetailContainer>
              </Subcategory>

              <Subcategory>
                <SubcategoryHeading>{i18n("Noun Type")}</SubcategoryHeading>

                <DetailContainer>
                  <Detail><Hash>#</Hash>{i18n("gentilic")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("proper-name")}</Detail>
                </DetailContainer>
              </Subcategory>

              <Subcategory>
                <SubcategoryHeading>{i18n("Adjective Type")}</SubcategoryHeading>

                <DetailContainer>
                  <Detail><Hash>#</Hash>{i18n("number")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("cardinal-number")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("ordinal-number")}</Detail>
                </DetailContainer>
              </Subcategory>

              <Subcategory>
                <SubcategoryHeading>{i18n("Particle Type")}</SubcategoryHeading>

                <DetailContainer>
                  <Detail><Hash>#</Hash>{i18n("negative")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("direct-object-marker")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("affirmation")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("exhortation")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("interjection")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("demonstrative")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("interrogative")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("relative")}</Detail>
                </DetailContainer>
              </Subcategory>

              <Category>
                <CategoryHeading>{i18n("Verb Stem")}</CategoryHeading>

                <DetailContainer>
                  <Detail><Hash>#</Hash>{i18n("qal")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("niphal")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("piel")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("pual")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("hiphil")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("hophal")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("hithpael")}</Detail>
                </DetailContainer>
              </Category>

              <Subcategory>
                <SubcategoryHeading>{i18n("Alternative Stems")}</SubcategoryHeading>

                <DetailContainer>
                  <Detail><Hash>#</Hash>{i18n("polel")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("polal")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("hithpolel")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("poel")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("poal")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("palel")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("pulal")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("qal-passive")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("pilpel")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("polpal")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("hithpalpel")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("nithpael")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("pealal")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("pilel")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("hothpaal")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("tiphil")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("hishtaphel")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("nithpalel")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("nithpoel")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("hithpoel")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("peal")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("peil")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("hithpeel")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("pael")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("ithpaal")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("hithpaal")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("aphel")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("haphel")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("saphel")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("shaphel")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("ithpeel")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("ishtaphel")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("hithaphel")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("ithpoel")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("hephal")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("tiphel")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("palpel")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("ithpalpel")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("ithpolel")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("ittaphal")}</Detail>
                </DetailContainer>
              </Subcategory>

              <Category>
                <CategoryHeading>{i18n("Verb Aspect")}</CategoryHeading>

                <DetailContainer>
                  <Detail><Hash>#</Hash>{i18n("perfect")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("imperfect")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("sequential-perfect")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("sequential-imperfect")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("cohortative")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("imperative")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("jussive")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("participle")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("passive-participle")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("infinitive-absolute")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("infinitive-construct")}</Detail>
                </DetailContainer>
              </Category>

              <Category>
                <CategoryHeading>{i18n("Person")}</CategoryHeading>

                <DetailContainer>
                  <Detail><Hash>#</Hash>{i18n("1st")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("2nd")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("3rd")}</Detail>
                </DetailContainer>
              </Category>

              <Category>
                <CategoryHeading>{i18n("Gender")}</CategoryHeading>

                <DetailContainer>
                  <Detail><Hash>#</Hash>{i18n("masculine")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("feminine")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("common")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("gender-both")}</Detail>
                </DetailContainer>
              </Category>

              <Category>
                <CategoryHeading>{i18n("Number")}</CategoryHeading>

                <DetailContainer>
                  <Detail><Hash>#</Hash>{i18n("singular")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("plural")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("dual")}</Detail>
                </DetailContainer>
              </Category>

              <Category>
                <CategoryHeading>{i18n("Noun State")}</CategoryHeading>

                <DetailContainer>
                  <Detail><Hash>#</Hash>{i18n("absolute")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("construct")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("determined")}</Detail>
                </DetailContainer>
              </Category>

              <Category>
                <CategoryHeading>{i18n("Prepositional Prefixes")}</CategoryHeading>

                <DetailContainer>
                  <Detail><Hash>#</Hash>{i18n("b")}</Detail>
                  <DetailExplanation>בְּ–</DetailExplanation>
                </DetailContainer>
                <DetailContainer>
                  <Detail><Hash>#</Hash>{i18n("l")}</Detail>
                  <DetailExplanation>לְ–</DetailExplanation>
                </DetailContainer>
                <DetailContainer>
                  <Detail><Hash>#</Hash>{i18n("k")}</Detail>
                  <DetailExplanation>כִּ–</DetailExplanation>
                </DetailContainer>
                <DetailContainer>
                  <Detail><Hash>#</Hash>{i18n("m")}</Detail>
                  <DetailExplanation>מֵ–</DetailExplanation>
                </DetailContainer>
              </Category>

              <Category>
                <CategoryHeading>{i18n("Pronominal Suffixes")}</CategoryHeading>

                <CategoryExplanation>
                  {i18nReact("Indicated with {{form}} using a single character for each detail (person, gender, and number).", {
                    form: <DetailWithoutMargin><Hash>#</Hash>{i18n("suffix")}:___</DetailWithoutMargin>,
                  })}
                </CategoryExplanation>
                <ExamplesHeading>{i18n("Examples")}</ExamplesHeading>
                <Examples>
                  <DetailContainer>
                    <Detail><Hash>#</Hash>{i18n("suffix:{{suffix}}", { suffix: i18n("1cs") })}</Detail>
                    <DetailExplanation>{i18n("1st common singular")}</DetailExplanation>
                  </DetailContainer>
                  <DetailContainer>
                    <Detail><Hash>#</Hash>{i18n("suffix:{{suffix}}", { suffix: i18n("3mp") })}</Detail>
                    <DetailExplanation>{i18n("3rd masculine plural")}</DetailExplanation>
                  </DetailContainer>
                </Examples>
              </Category>

              <Category>
                <CategoryHeading>{i18n("Other Prefixes")}</CategoryHeading>

                <DetailContainer>
                  <Detail><Hash>#</Hash>{i18n("v")}</Detail>
                  <DetailExplanation>וְ–</DetailExplanation>
                  {` `}
                  {i18n("(conjunction)")}
                </DetailContainer>
                <DetailContainer>
                  <Detail><Hash>#</Hash>{i18n("sh")}</Detail>
                  <DetailExplanation>שֶׁ–</DetailExplanation>
                  {` `}
                  {i18n("(relative pronoun)")}
                </DetailContainer>
                <DetailContainer>
                  <Detail><Hash>#</Hash>{i18n("h")}</Detail>
                  <DetailExplanation>הַ–</DetailExplanation>
                  {` `}
                  {i18n("(definite article)")}
                </DetailContainer>
                <DetailContainer>
                  <Detail><Hash>#</Hash>{i18n("h!")}</Detail>
                  <DetailExplanation>הַ–</DetailExplanation>
                  {` `}
                  {i18n("(explicit definite article only)")}
                </DetailContainer>
                <DetailContainer>
                  <Detail><Hash>#</Hash>{i18n("h'")}</Detail>
                  {i18n("(implicit definite article contained within בָּ or לָ or כַּ prefix)")}
                </DetailContainer>
                <DetailContainer>
                  <Detail><Hash>#</Hash>{i18n("h?")}</Detail>
                  <DetailExplanation>הֲ–</DetailExplanation>
                  {` `}
                  {i18n("(interrogative)")}
                </DetailContainer>
              </Category>

              <Category>
                <CategoryHeading>{i18n("Other Suffixes")}</CategoryHeading>

                <DetailContainer>
                  <Detail><Hash>#</Hash>{i18n("h->")}</Detail>
                  <DetailExplanation>–ה</DetailExplanation>
                  {` `}
                  {i18n("(directional)")}
                </DetailContainer>
                <DetailContainer>
                  <Detail><Hash>#</Hash>{i18n("h^")}</Detail>
                  <DetailExplanation>–ה</DetailExplanation>
                  {` `}
                  {i18n("(paragogic)")}
                </DetailContainer>
                <DetailContainer>
                  <Detail><Hash>#</Hash>{i18n("n^")}</Detail>
                  <DetailExplanation>–ן</DetailExplanation>
                  {` `}
                  {i18n("(paragogic)")}
                </DetailContainer>
              </Category>

            </TabContent>
          }

          {tabIdx === 1 &&
            <TabContent>

              <Category>
                <CategoryHeading>{i18n("Part of Speech")}</CategoryHeading>

                <DetailContainer>
                  <Detail><Hash>#</Hash>{i18n("noun")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("pronoun")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("adjective")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("verb")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("adverb")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("preposition")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("conjunction")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("particle")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("determiner")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("foreign")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("interjection")}</Detail>
                </DetailContainer>
              </Category>

              <Subcategory>
                <SubcategoryHeading>{i18n("Pronoun Type")}</SubcategoryHeading>

                <DetailContainer>
                  <Detail><Hash>#</Hash>{i18n("reflexive")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("reciprocal")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("demonstrative")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("indefinite")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("personal")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("interrogative")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("relative")}</Detail>
                </DetailContainer>
              </Subcategory>

              <Subcategory>
                <SubcategoryHeading>{i18n("Adjective Type")}</SubcategoryHeading>

                <DetailContainer>
                  <Detail><Hash>#</Hash>{i18n("substantive")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("predicate")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("ascriptive")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("restrictive")}</Detail>
                </DetailContainer>
              </Subcategory>

              <Subcategory>
                <SubcategoryHeading>{i18n("Verb Type")}</SubcategoryHeading>

                <DetailContainer>
                  <Detail><Hash>#</Hash>{i18n("transitive")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("intransitive")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("linking")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("modal")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("periphrastic")}</Detail>
                </DetailContainer>
              </Subcategory>

              <Subcategory>
                <SubcategoryHeading>{i18n("Adverb Type")}</SubcategoryHeading>

                <DetailContainer>
                  <Detail><Hash>#</Hash>{i18n("improper-preposition")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("correlative")}</Detail>
                </DetailContainer>
              </Subcategory>

              <Subcategory>
                <SubcategoryHeading>{i18n("Conjunction Type")}</SubcategoryHeading>

                <DetailContainer>
                  <Detail><Hash>#</Hash>{i18n("coordinating")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("subordinating")}</Detail>
                </DetailContainer>
              </Subcategory>

              <Subcategory>
                <SubcategoryHeading>{i18n("Determiner Type")}</SubcategoryHeading>

                <DetailContainer>
                  <Detail><Hash>#</Hash>{i18n("article")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("differential")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("possessive")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("quantifier")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("ordinal")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("demonstrative")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("interrogative")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("relative")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("number")}</Detail>
                </DetailContainer>
              </Subcategory>

              <Subcategory>
                <SubcategoryHeading>{i18n("Interjection Type")}</SubcategoryHeading>

                <DetailContainer>
                  <Detail><Hash>#</Hash>{i18n("exclamation")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("directive")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("response")}</Detail>
                </DetailContainer>
              </Subcategory>

              <Category>
                <CategoryHeading>{i18n("Aspect")}</CategoryHeading>

                <DetailContainer>
                  <Detail><Hash>#</Hash>{i18n("present")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("future")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("aorist")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("perfect")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("imperfect")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("pluperfect")}</Detail>
                </DetailContainer>
              </Category>

              <Category>
                <CategoryHeading>{i18n("Voice")}</CategoryHeading>

                <DetailContainer>
                  <Detail><Hash>#</Hash>{i18n("active")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("middle")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("passive")}</Detail>
                </DetailContainer>
              </Category>

              <Category>
                <CategoryHeading>{i18n("Mood")}</CategoryHeading>

                <DetailContainer>
                  <Detail><Hash>#</Hash>{i18n("indicative")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("subjunctive")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("imperative")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("infinitive")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("participle")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("optative")}</Detail>
                </DetailContainer>
              </Category>

              <Category>
                <CategoryHeading>{i18n("Person")}</CategoryHeading>

                <DetailContainer>
                  <Detail><Hash>#</Hash>{i18n("1st")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("2nd")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("3rd")}</Detail>
                </DetailContainer>
              </Category>

              <Category>
                <CategoryHeading>{i18n("Gender")}</CategoryHeading>

                <DetailContainer>
                  <Detail><Hash>#</Hash>{i18n("neuter")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("masculine")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("feminine")}</Detail>
                </DetailContainer>
              </Category>

              <Category>
                <CategoryHeading>{i18n("Number")}</CategoryHeading>

                <DetailContainer>
                  <Detail><Hash>#</Hash>{i18n("singular")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("plural")}</Detail>
                </DetailContainer>
              </Category>

              <Category>
                <CategoryHeading>{i18n("Case")}</CategoryHeading>

                <DetailContainer>
                  <Detail><Hash>#</Hash>{i18n("nominative")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("genitive")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("dative")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("accusative")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("vocative")}</Detail>
                </DetailContainer>
              </Category>

              <Category>
                <CategoryHeading>{i18n("Attribute")}</CategoryHeading>

                <DetailContainer>
                  <Detail><Hash>#</Hash>{i18n("comparative")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("superlatives")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("diminutive")}</Detail>
                  <Detail><Hash>#</Hash>{i18n("indeclinable")}</Detail>
                </DetailContainer>
              </Category>

            </TabContent>
          }

        </Content>
      </Container>

    </>
  )
}


export default memo(GrammarSearchKey)