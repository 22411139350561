import { memo } from 'react'
import styled from 'styled-components'
// import { i18n } from 'inline-i18n'

// import { LoggedInUserContext } from '../../../context/LoggedInUser'

const Container = styled.div`
  margin: 20px 20px 0;
  padding: 15px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 10px;
  max-width: 1240px;
`

const SubscriptionStatusNote = ({
  legacyCredit,
}) => {

  // const user = useContext(LoggedInUserContext)

  return (
    <>

      {!!legacyCredit &&
        <Container>
          Note: Your existing Biblearc subscription will be replaced with your selection below and a discount of ${legacyCredit.toFixed(2)} (your credit + the amount remaining on your subscription) will be applied.
        </Container>
      }

    </>
  )
}


export default memo(SubscriptionStatusNote)