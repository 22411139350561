// i18n-default-category:"original-languages"

import { memo, useCallback } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import Button from '@material-ui/core/Button'
import InfoIcon from '@material-ui/icons/Info'

import useModalAnchor from '../../hooks/useModalAnchor'
import useVersionInfo from '../../hooks/useVersionInfo'
import { preventDefaultEvent } from '../../utils/misc'
import i18nReact from '../../utils/i18nReact'

import BasicPopover from '../common/BasicPopover'
import PassageRef from '../common/PassageRef'
import LinkButton from '../common/LinkButton'
import { getLocFromRef } from '@bibletags/bibletags-versification'

const Showing = styled.div`
  color: rgb(255 255 255/.85);
  text-align: center;
  font-weight: 300;
  margin-bottom: 3px;
  font-size: 14px;
`

const ShowContainer = styled.span`
  font-size: 13px;
`

const Show = styled.span`
  color: ${({ theme }) => theme.palette.primary.main};
  transition: .15s color ease-in-out;

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.palette.primary.light};
  }
`

const ClickForInfo = styled.div`
  color: rgb(255 255 255/.5);
  text-align: center;
  font-weight: 300;
  margin-bottom: 7px;
  font-size: 14px;
`

const ApparatusButtonsContainer = styled.div`
  margin: 5px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledButton = styled(Button)`
  align-self: center;
  background: ${({ theme, $withCriticalAlt }) => (
    $withCriticalAlt
      ? `${theme.palette.primary.main}44`
      : `${theme.palette.grey[700]}aa`
  )};
  color: rgb(255 255 255/.8);
  font-weight: 400;
  font-size: 11.5px;
  border-radius: 50px;
  padding: 3px 14px;
  margin: 5px 0;

  &:hover {
    background: ${({ theme, $withCriticalAlt }) => (
      $withCriticalAlt
        ? `${theme.palette.primary.main}66`
        : `${theme.palette.grey[700]}`
    )};
  }
`

const StyledInfoIcon = styled(InfoIcon)`
  color: ${({ theme, $tagSetStatus }) => (
    ($tagSetStatus === `confirmed` && theme.palette.grey[500])
    || theme.palette.primary.main
  )};
  opacity: .5;
  margin: -10px 0 -9px 5px;
  height: 18px;
  width: 18px;
  vertical-align: middle;
  transition: .15s opacity ease-in-out;

  &:hover {
    cursor: pointer;
    opacity: 1;
  }
`

const StyledBasicPopover = styled(BasicPopover)`
  .MuiPopover-paper {
    padding: 8px 10px;
    margin-top: 5px;
    max-width: 300px;
  }
`

const TaggingStatusMessage = styled.div`
`

const Status = styled.span`
  font-weight: bold;
  display: inline-block;
`

const TaggingStatusExplanation = styled.div`
  margin: 5px 0;
  font-weight: 300;
  line-height: 1.2;
`

const ClickWordForMoreInfo = ({
  contextRef,
  compareOrigTexts,
  apparatusItems,
  tagSetStatus,
  showGloss,
  setShowGloss,
  versionId,
}) => {

  const { safeVersionAbbr } = useVersionInfo(versionId)
  const language = contextRef.bookId >= 40 ? i18n("Greek") : i18n("Hebrew")

  const toggleShowGloss = useCallback(() => setShowGloss(!showGloss), [ setShowGloss, showGloss ])

  const { anchorEl, openModal, closeModal } = useModalAnchor()

  const openModalAndPreventDefault = useCallback(
    event => {
      preventDefaultEvent(event)
      openModal(event)
    },
    [ openModal ],
  )
  
  return (
    <>

      <Showing>
        {i18n("Showing {{item1}} + {{item2}}.", {
          item1: language,
          item2: (
            showGloss
              ? i18n("gloss")
              : safeVersionAbbr
          ),
        })}
        {![ undefined, `automatch`, `none` ].includes(tagSetStatus) &&
          <>
            {` `}
            <ShowContainer>
              {i18nReact("({{parenthetical_text}})", {
                parenthetical_text: (
                  <Show onClick={toggleShowGloss}>
                    {i18n("Show {{version}}", {
                      version: (
                        showGloss
                          ? safeVersionAbbr
                          : i18n("gloss")
                      ),
                    })}
                  </Show>
                ),
              })}
            </ShowContainer>
          </>
        }
        <StyledInfoIcon
          $tagSetStatus={tagSetStatus}
          onClick={openModalAndPreventDefault}
        />
      </Showing>

      <ClickForInfo>
        {i18n("Click a {{language}} word for more info.", { language })}
      </ClickForInfo>

      <ApparatusButtonsContainer>
        {apparatusItems.map((apparatusItem, idx) => (
          <StyledButton
            key={idx}
            variant="contained"
            disableElevation
            onClick={() => compareOrigTexts(apparatusItem)}
            $withCriticalAlt={apparatusItem.withCriticalAlt}
          >
            {contextRef.bookId >= 40
              ? i18n("Compare Greek Texts")
              : i18n("Compare Hebrew Readings")
            }
            {apparatusItems.length > 1 &&
              <>
                {` `}
                {i18nReact("({{parenthetical_text}})", {
                  parenthetical_text: (
                    <PassageRef
                      refs={[apparatusItem.originalRef]}
                      withoutBookName
                    />
                  ),
                })}
              </>
            }
          </StyledButton>
        ))}
      </ApparatusButtonsContainer>

      <StyledBasicPopover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={closeModal}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}    
      >

        {tagSetStatus === `unconfirmed` &&
          <>
            <TaggingStatusMessage>
              {i18nReact("Tagging Status: {{status}}", {
                status: (
                  <Status>
                    {i18n("Provisional")}
                  </Status>
                ),
              })}
            </TaggingStatusMessage>
            <TaggingStatusExplanation>
              {i18n("This {{version}} of this verse has been tagged to the {{language}}, but those tags have yet to be double-checked and confirmed.", {
                version: safeVersionAbbr,
                language,
              })}
            </TaggingStatusExplanation>
          </>
        }

        {tagSetStatus === `confirmed` &&
          <>
            <TaggingStatusMessage>
              {i18nReact("Tagging Status: {{status}}", {
                status: (
                  <Status>
                    {i18n("Confirmed")}
                  </Status>
                ),
              })}
            </TaggingStatusMessage>
            <TaggingStatusExplanation>
              {i18n("This {{version}}‘s tags for this verse have been double-checked and confirmed.", {
                version: safeVersionAbbr,
                language,
              })}
            </TaggingStatusExplanation>
          </>
        }

        {[ undefined, `automatch`, `none` ].includes(tagSetStatus) &&
          <>
            <TaggingStatusMessage>
              {i18nReact("Tagging Status: {{status}}", {
                status: (
                  <Status>
                    {i18n("Not yet tagged")}
                  </Status>
                ),
              })}
            </TaggingStatusMessage>
            <TaggingStatusExplanation>
              {i18n("This {{version}} of this verse has not yet been tagged to the {{language}}.", {
                version: safeVersionAbbr,
                language,
              })}
            </TaggingStatusExplanation>
          </>
        }

        <LinkButton
          fullWidth
          variant="contained"
          disableElevation
          color="primary"
          size="small"
          to={`/tag?versionId=${encodeURIComponent(versionId)}&loc=${encodeURIComponent(getLocFromRef(contextRef).split(`:`)[0])}`}
        >
          {[ `confirmed`, `unconfirmed` ].includes(tagSetStatus)
            ? i18n("Correct tags for this verse")
            : i18n("Tag this verse")
          }
        </LinkButton>

      </StyledBasicPopover>

    </>
  )
}

export default memo(ClickWordForMoreInfo)