import { memo, useCallback, useState, useMemo, useContext } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import { useTransition } from "@react-spring/web"
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import SendIcon from '@material-ui/icons/Send'
import { useMutation } from '@apollo/client'

import { LoggedInUserContext } from '../../context/LoggedInUser'
import useDataQuery from '../../hooks/useDataQuery'
import useRefState from '../../hooks/useRefState'
import useStickyRefState from '../../hooks/useStickyRefState'
import useLogInCallback from '../../hooks/useLogInCallback'
import { getModifierChar, usingModifierKey, doWhitespaceTrim } from '../../utils/misc'
import i18nReact from '../../utils/i18nReact'

import StudyBibleItemComment from './StudyBibleItemComment'
import FadedLoading from '../common/FadedLoading'
import TextAndShortcutCombo from '../common/TextAndShortcutCombo'

import studyBibleItemCommentsQuery from '../../graphql/queries/studyBibleItemComments'
import createStudyBibleItemCommentMutation from '../../graphql/mutations/createStudyBibleItemComment'

const DEFAULT_COMMENT_HEIGHT = 30
const COMMENT_MARGIN_BOTTOM = 10
const INITIAL_PAGE_SIZE = 5
const ADDITIONAL_PAGE_SIZE = 15

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

const StyledTextField = styled(TextField)`

  .MuiInputBase-root {
    padding: 0;
    font-size: inherit;
    line-height: inherit;
  }

  .MuiOutlinedInput-notchedOutline {
    display: none;
  } 

`

const StyledIconButtonContainer = styled.div`
  position: absolute;
  bottom: -5px;
  right: -5px;
`

const StyledIconButton = styled(IconButton)`
  color: rgb(0 0 0/.8) !important;
  transition: opacity .25s ease-in-out, background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  opacity: ${({ disabled }) => disabled ? 0 : 1};
  width: 36px;
  height: 36px;

  .MuiSvgIcon-root {
    transform: translateX(1px);
    height: 18px;
    width: 18px;
  }
`

const StyledStudyBibleItemComment = styled(StudyBibleItemComment)`
  margin-bottom: ${COMMENT_MARGIN_BOTTOM}px;
  position: relative;

  .StudyBibleItemComment-Content {
    background: none;
    outline: 1px solid ${({ theme }) => theme.palette.primary.faded};
  }

`

const NewComment = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  padding-bottom: ${({ $disabled }) => $disabled ? 0 : 30}px;
  transition: padding-bottom .25s ease-in-out;
`

const Comments = styled.div`
  position: relative;
  height: ${({ $heightOfComments }) => $heightOfComments}px;
`

const NoLoginMessage = styled.div`
  position: relative;
  text-align: center;
  margin: 0 15px 20px;
  font-size: 14px;
  font-weight: 300;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  padding: 20px;
`

const LoginLink = styled.span`
  display: inline-block;
  color: ${({ theme }) => theme.palette.primary.main};
  text-decoration: underline;
  transition: color .15s ease-in-out;

  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      color: ${({ theme }) => theme.palette.primary.dark};
    }
  }
`

const MoreButton = styled(Button)`
  margin-top: 10px;
  align-self: center;
`

const StudyBibleItemComments = ({
  defaultInputForNew,
  query,
  reactionTypes,
  allowReactionsToSelf,
  enableCheckmark,
  refetchItem,
  ...otherProps
}) => {

  const user = useContext(LoggedInUserContext)
  const [ limit, setLimit ] = useRefState(INITIAL_PAGE_SIZE)
  const [ addingNew, setAddingNew ] = useState(false)
  const [ heights, setHeights, getHeights ] = useRefState({})
  const totalHeight = Math.max(Object.values(heights).reduce((a,b) => a + b + (b ? COMMENT_MARGIN_BOTTOM : 0), -COMMENT_MARGIN_BOTTOM), 100)
  const [ newCommentContent, setNewCommentContent, getNewCommentContent ] = useStickyRefState({ id: `StudyBibleItemComments:${JSON.stringify(defaultInputForNew)}`, defaultValue: `` })
  const disabled = !doWhitespaceTrim(newCommentContent)
  const { logIn, navigatingToLogin } = useLogInCallback()

  const [ createStudyBibleItemComment ] = useMutation(createStudyBibleItemCommentMutation)

  const isInternalCommentsQuery = /(?:^| )type:INTERNAL(?: |$)/.test(query)
  let { studyBibleItemComments: studyBibleItemCommentsAndCount, loading, refetch, previousData } = useDataQuery({
    studyBibleItemCommentsQuery,
    pollInterval: 5000,
    fetchPolicy: (
      isInternalCommentsQuery
        ? 'network-only'
        : 'cache-and-network'
    ),
    variables: {
      query,
      limit,
    },
    dataOnError: null,
  })

  if(loading && previousData && !studyBibleItemCommentsAndCount) {
    studyBibleItemCommentsAndCount = previousData.studyBibleItemComments
  }

  const { studyBibleItemComments=[], count=0 } = studyBibleItemCommentsAndCount || {}

  const commentsWithY = useMemo(
    () => {
      let cummulativeHeight = 0
      return (
        studyBibleItemComments.map(comment => {
          const y = cummulativeHeight
          cummulativeHeight += (heights[comment.id] || DEFAULT_COMMENT_HEIGHT) + COMMENT_MARGIN_BOTTOM
          return { ...comment, y }
        })
      )
    },
    [ studyBibleItemComments, heights ],
  )

  const transitions = useTransition(
    commentsWithY,
    {
      from: { position: "absolute", opacity: 0 },
      leave: { height: 0, opacity: 0 },
      enter: ({ y }) => ({ y, opacity: 1 }),
      update: ({ y }) => ({ y }),
      key: (item) => item?.id
    },
  )

  const updateHeight = useCallback(
    ({ id, height }) => {
      setHeights({
        ...getHeights(),
        [id]: height,
      })
    },
    [ setHeights, getHeights ],
  )

  const onLoadMoreClick = useCallback(() => setLimit(limit + ADDITIONAL_PAGE_SIZE), [ setLimit, limit ])

  const addNewComment = useCallback(
    async () => {

      setAddingNew(true)

      await createStudyBibleItemComment({
        variables: {
          input: {
            ...defaultInputForNew,
            content: doWhitespaceTrim(getNewCommentContent()),
          },
        },
      })

      await Promise.all([ refetch(), refetchItem() ])

      setNewCommentContent(``)
      setAddingNew(false)

    },
    [ createStudyBibleItemComment, getNewCommentContent, defaultInputForNew, refetch, refetchItem, setNewCommentContent ],
  )

  const onNewCommentContentChange = useCallback(({ target }) => setNewCommentContent(target.value), [ setNewCommentContent ])

  const onNewCommentContentKeyDown = useCallback(
    event => {
      if(
        event.key === `Enter`
        && usingModifierKey(event)
        && !disabled
      ) {
        addNewComment()
      }
    },
    [ addNewComment, disabled ]
  )

  return (
    <Container {...otherProps}>

      {!!user &&
        <StyledStudyBibleItemComment
          content={
            <>

              <NewComment
                $disabled={disabled}
              >
                <StyledTextField
                  variant="outlined"
                  size="small"
                  value={newCommentContent}
                  onChange={onNewCommentContentChange}
                  onKeyDown={onNewCommentContentKeyDown}
                  multiline
                  minRows={1}
                  fullWidth
                  placeholder={i18n("Add a new comment")}
                />

                <Tooltip
                  title={
                    <TextAndShortcutCombo
                      text={i18n("Comment")}
                      shortcutCombo={`${getModifierChar()}↵`}
                    />
                  }
                >
                  <StyledIconButtonContainer>
                    <StyledIconButton
                      onClick={addNewComment}
                      disabled={addingNew || disabled}
                    >
                      <SendIcon />
                    </StyledIconButton>
                  </StyledIconButtonContainer>
                </Tooltip>
              </NewComment>

              {addingNew && <FadedLoading />}

            </>
          }
          user={user}
        />
      }

      {!user &&
        <NoLoginMessage>
          {i18nReact("You must {{sign_in}} to comment.", {
            sign_in: (
              <LoginLink onClick={logIn}>
                {i18n("sign in or create an account")}
              </LoginLink>
            )
          })}
          {navigatingToLogin && <FadedLoading size={20} />}
        </NoLoginMessage>
      }

      <Comments
        $heightOfComments={totalHeight}
      >

        {transitions((style, { __typename, y, ...item }) => (
          <StudyBibleItemComment
            key={item.id}
            {...item}
            isMyComment={item.user.id === (user || {}).id}
            reactionTypes={reactionTypes}
            allowReactionsToSelf={allowReactionsToSelf}
            enableCheckmark={enableCheckmark}
            style={style}
            updateHeight={updateHeight}
            refetch={refetch}
            refetchItem={refetchItem}
          />
        ))}

        {!count && loading && <FadedLoading />}

      </Comments>

      {count > commentsWithY.length &&
        <MoreButton
          onClick={onLoadMoreClick}
          variant="outlined"
          disabled={loading}
        >
          {i18n("Load more")}
          {loading && <FadedLoading size={20} />}
        </MoreButton>
      }

    </Container>
  )
}

export default memo(StudyBibleItemComments)