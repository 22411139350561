import { memo, useContext } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import Divider from '@material-ui/core/Divider'
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo'

import i18nReact from '../../../utils/i18nReact'
import { LoggedInUserContext } from '../../../context/LoggedInUser'

import SettingsListHeader from "./SettingsListHeader"
import MyChannelsList from "./MyChannelsList"
import MyPlanPromoSpot from '../../common/MyPlanPromoSpot'

const ListContainer = styled.div`
  min-width: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 800px;
  border-right: 1px solid ${({ theme }) => theme.palette.divider};
  margin-right: -1px;
`

const StyledOndemandVideoIcon = styled(OndemandVideoIcon)`
  height: 26px;
  width: auto;
  margin: 0 10px 0 1px;
`

const LogoImg = styled.img`
  height: 35px;
  vertical-align: middle;
  margin: -13px -6px -10px -6px;

  @media(max-width: 499px) {
    display: none;
  }
`

const SettingsChannels = ({
  expandLeftPanel,
}) => {

  const { hasMyPlan } = useContext(LoggedInUserContext) || {}

  return (
    <>

      <ListContainer>

        <SettingsListHeader
          icon={<StyledOndemandVideoIcon />}
          expandLeftPanel={expandLeftPanel}
          extraInfo={hasMyPlan && (
            <>
              {i18n("Personalize your study Bible by following, unfollowing, and reordering channels.")}
              {` `}
              {i18n("(Place highest priority channels at the top.)")}
            </>
          )}
        >
          {i18nReact("{{logo}} Channels", {
            logo: <LogoImg src="/my_biblearc_study_bible_1.svg" />,
          })}
        </SettingsListHeader>

        <Divider />

        {hasMyPlan && <MyChannelsList />}

        <MyPlanPromoSpot
          subscribeToMessage={i18n("Subscribe to add and manage channels.")}
        />

      </ListContainer>

    </>
  )
}

export default memo(SettingsChannels)