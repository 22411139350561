import { memo, useCallback } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'

import useInstanceValue from '../../hooks/useInstanceValue'
import { getLocalStorage } from '../../utils/misc'
import i18nReact from '../../utils/i18nReact'

import CustomSlider from './CustomSlider'

const Container = styled.div`
  margin-bottom: 15px;
`

const StyledTextField = styled(TextField)`
  margin: 10px 0;
`

const StyledCustomSlider = styled(CustomSlider)`
  margin: 10px 0;
`

const Note = styled.div`
  color: ${({ theme }) => theme.palette.grey[500]};
  margin-bottom: 20px;
  font-size: 13px;
`

const NumPages = styled.span`
  opacity: ${({ $hide }) => $hide ? .3 : 1};
`

const PrintAndPdfOptions = ({
  action,
  format,
  pageSize,
  orientation,
  marginsInChange,
  moduleWidth,
  moduleWidthInChange,
  updateInfo,
  captureInfo,
  defaultImageWidth,
}) => {

  const onChangeFormat = useCallback(
    ({ target }) => {
      const moduleWidth = getLocalStorage(`initialValue:moduleWidth:${target.value}`, defaultImageWidth)
      updateInfo({
        ...getLocalStorage(`initialValue:${target.value}Options`, {}),
        format: target.value,
        moduleWidth,
        moduleWidthInChange: moduleWidth,
      })
    },
    [ updateInfo, defaultImageWidth ],
  )

  const onChangePageSize = useCallback(({ target }) => updateInfo({ pageSize: target.value }), [ updateInfo ])
  const onChangeOrientation = useCallback(({ target }) => updateInfo({ orientation: target.value }), [ updateInfo ])

  const updateModuleWidthInChange = useCallback((event, value) => updateInfo({ moduleWidthInChange: -value }), [ updateInfo ])
  const getModuleWidthInChange = useInstanceValue(moduleWidthInChange)
  const commitModuleWidth = useCallback(
    () => {
      updateInfo({
        moduleWidth: getModuleWidthInChange(),
      })
    },
    [ updateInfo, getModuleWidthInChange ],
  )

  const updateMarginsInChange = useCallback((event, value) => updateInfo({ marginsInChange: value }), [ updateInfo ])
  const getMarginsInChange = useInstanceValue(marginsInChange)
  const commitMargins = useCallback(
    () => {
      updateInfo({
        margins: getMarginsInChange(),
      })
    },
    [ updateInfo, getMarginsInChange ],
  )

  const resizingModuleWidth = moduleWidth !== moduleWidthInChange
  const waitingToRenderNewModuleWidth = moduleWidth !== (captureInfo.options || {}).moduleWidth
  const resizingOrRenderingModuleWidth = resizingModuleWidth || waitingToRenderNewModuleWidth
  const fontSizeAdjustment = (captureInfo.options || {}).moduleWidth / moduleWidthInChange
  const baseFontSize = (captureInfo.scale * fontSizeAdjustment * 27.7).toFixed(1)

  return (
    <Container>

      {action === `download` &&
        <StyledTextField
          select
          label={i18n("Format")}
          fullWidth
          variant="outlined"
          value={format}
          color="secondary"
          size="small"
          onChange={onChangeFormat}
        >
          <MenuItem
            value="png"
          >
            {i18n("PNG (image)")}
          </MenuItem>
          <MenuItem
            value="pdf"
          >
            {i18n("PDF (document)")}
          </MenuItem>
          {/* <MenuItem
            value="svg"
          >
            {i18n("SVG (vector graphic)")}
          </MenuItem> */}
        </StyledTextField>
      }

      <StyledTextField
        select
        label={i18n("Page Size")}
        fullWidth
        variant="outlined"
        value={pageSize}
        color="secondary"
        size="small"
        onChange={onChangePageSize}
      >
        <MenuItem
          value="letter"
        >
          {i18n("Letter (8.5\" x 11\")")}
        </MenuItem>
        <MenuItem
          value="legal"
        >
          {i18n("Legal (8.5\" x 14\")")}
        </MenuItem>
        <MenuItem
          value="tabloid"
        >
          {i18n("Tabloid (11\" x 17\")")}
        </MenuItem>
        <MenuItem
          value="a3"
        >
          {i18n("A3 (297mm x 420mm)")}
        </MenuItem>
        <MenuItem
          value="a4"
        >
          {i18n("A4 (210mm x 297mm)")}
        </MenuItem>
        <MenuItem
          value="a5"
        >
          {i18n("A5 (148mm x 210mm)")}
        </MenuItem>
      </StyledTextField>

      <StyledTextField
        select
        label={i18n("Orientation")}
        fullWidth
        variant="outlined"
        value={orientation}
        color="secondary"
        size="small"
        onChange={onChangeOrientation}
      >
        <MenuItem
          value="portrait"
        >
          {i18n("Portrait")}
        </MenuItem>
        <MenuItem
          value="landscape"
        >
          {i18n("Landscape")}
        </MenuItem>
      </StyledTextField>

      <StyledCustomSlider
        label={i18n("Zoom")}
        min={-1985}  // use negatives so it is reverse direction
        max={-285}
        step={17}
        color="secondary"
        value={-moduleWidthInChange}
        onChange={updateModuleWidthInChange}
        onChangeCommitted={commitModuleWidth}
      />

      <StyledCustomSlider
        label={i18n("Margins")}
        min={0}
        max={144}  // 144 === 2 inch margin
        color="secondary"
        value={marginsInChange}
        onChange={updateMarginsInChange}
        onChangeCommitted={commitMargins}
      />

      <Note>
        {i18nReact("{{title}} ({{subtitle}})", {
          title: (
            <NumPages $hide={resizingOrRenderingModuleWidth}>
              {captureInfo.numPages === 1 && i18n("1 page")}
              {captureInfo.numPages > 1 && (
                i18n("{{number}} pages", {
                  number: captureInfo.numPages,
                })
              )}
            </NumPages>
          ),
          subtitle: (
            i18n("{{number}}pt base font", {
              number: baseFontSize,
            })
          ),
        })}
      </Note>

    </Container>
  )
}

export default memo(PrintAndPdfOptions)