import { memo, useCallback } from 'react'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
// import Tooltip from '@material-ui/core/Tooltip'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { i18n } from 'inline-i18n'
import { useMutation } from '@apollo/client'

import useModalAnchor from "../../../hooks/useModalAnchor"
import useMutationContext from '../../../hooks/useMutationContext'
import useSimpleToggle from '../../../hooks/useSimpleToggle'
import i18nReact from '../../../utils/i18nReact'

import MenuDivider from '../../common/MenuDivider'
import DangerMenuItem from '../../common/DangerMenuItem'
import InfoDialog from '../../common/InfoDialog'
import NavLinkOrAWithDisable from '../../common/NavLinkOrAWithDisable'

import deleteStudyBibleFollowMutation from '../../../graphql/mutations/deleteStudyBibleFollow'

const Container = styled.div`
  padding: 0 5px;
  margin-right: -10px;
`

const AboutUs = styled.div`
`

const AboutUsHeading = styled.div`
  font-weight: 700;
  font-size: 15px;
  margin: 10px 0;
`

const Explanation = styled.div`
  display: flex;
  flex-direction: column;
`

const BaseInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Image = styled.img`
  max-width: 100%;
  margin-bottom: 20px;
`

const Location = styled.div`
`

const Minister = styled.div`
`

const NumberOfSermons = styled.div`
  margin: 20px 0;
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 100px;
  padding: 10px;
  text-align: center;
  line-height: 1;
  font-weight: 300;
`

const Num = styled.div`
  font-weight: bold;
  font-size: 20px;
`

const Website = styled(NavLinkOrAWithDisable)`
`

const Phone = styled.div`
`


const MyChannelsListOptionsMenu = ({
  channelId,
  hide,
  toggleHide,
  aboutUs,
  displayName,
  imageURL,
  location,
  minister,
  numberOfSermons,
  homePageURL,
  phone,
}) => {

  const { anchorEl, openModal, closeModal } = useModalAnchor()
  const [ showInfo, toggleShowInfo ] = useSimpleToggle()

  const [ deleteStudyBibleFollow ] = useMutation(deleteStudyBibleFollowMutation)
  const context = useMutationContext()

  const unfollow = useCallback(
    async () => {
      await deleteStudyBibleFollow({
        variables: {
          id: channelId,
        },
        context,
      })
    },
    [ deleteStudyBibleFollow, channelId, context ],
  )

  const goToggleHide = useCallback(
    () => {
      closeModal()
      toggleHide()
    },
    [ closeModal, toggleHide ],
  )

  const goShowInfo = useCallback(
    () => {
      closeModal()
      toggleShowInfo()
    },
    [ closeModal, toggleShowInfo ],
  )

  return (
    <Container>

      <IconButton
        onClick={openModal}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={closeModal}
      >

        {!!channelId &&
          <>

            <MenuItem
              onClick={goShowInfo}
            >
              {i18n("More information")}
            </MenuItem>

            <MenuDivider />

            <DangerMenuItem
              onClick={unfollow}
            >
              {i18n("Unfollow")}
            </DangerMenuItem>

          </>
        }

        {!channelId &&
          <MenuItem
            onClick={goToggleHide}
          >
            {hide ? i18n("Follow") : i18n("Unfollow")}
          </MenuItem>
        }

      </Menu>

      <InfoDialog
        open={showInfo}
        onOkay={toggleShowInfo}
        okayButtonLabel={i18n("Close")}
        title={displayName}
        explanation={
          <Explanation>
            <BaseInfo>
              {!!imageURL &&
                <Image
                  src={imageURL}
                  className="dark-mode-exempt"
                />
              }
              {!!location &&
                <Location>
                  {location}
                </Location>
              }
              {!!minister &&
                <Minister>
                  {minister}
                </Minister>
              }
              {!!homePageURL &&
                <div>
                  <Website to={homePageURL}>
                    {homePageURL}
                  </Website>
                </div>
              }
              {!!phone &&
                <Phone>
                  {phone}
                </Phone>
              }
              {!!numberOfSermons &&
                <NumberOfSermons>
                  {i18nReact("{{num}} sermons", {
                    num: (
                      <Num>
                        {numberOfSermons}
                      </Num>
                    ),
                  })}
                </NumberOfSermons>
              }
            </BaseInfo>
            {!!aboutUs &&
              <>
                <AboutUsHeading>
                  {i18n("About")}
                </AboutUsHeading>
                <AboutUs>
                  {aboutUs}
                </AboutUs>
              </>
            }
          </Explanation>
        }
        color="secondary"
      />

    </Container>
  )
}

export default memo(MyChannelsListOptionsMenu)