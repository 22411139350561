import { memo, useRef, useCallback, useState } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'

import useLayoutEffectAsync from '../../../hooks/useLayoutEffectAsync'
import useInstanceValue from '../../../hooks/useInstanceValue'
import { preventDefaultEvent } from '../../../utils/misc'
import i18nReact from '../../../utils/i18nReact'

const BASE_IMAGE_WIDTH = 300
const MIN_SIZE = 512
const MASK_BORDER_OFFSET = 2000
const HEADER_ICON_SIZE = 29
const APP_ICON_SIZE = 48
const APP_ICON_INNER_SIZE = 31

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
`

const AdjustmentContainer = styled.div`
  margin-bottom: 15px;
  position: relative;

  ${({ $hide }) => !$hide ? `` : `
    visibility: hidden;
    position: absolute;
  `}
`

const ClearImageIconButton = styled(IconButton)`
  position: absolute;
  right: -42px;
  top: 0;
  padding: 10px;

  .MuiSvgIcon-root {
    font-size: 18px;
  }

`

const BaseImageContainer = styled.div`
  position: relative;
  overflow: hidden;
  background: white;
`

const BaseImage = styled.img`
  width: ${BASE_IMAGE_WIDTH}px;
  vertical-align: middle;
`

const CropSquare = styled.div`
  border: ${MASK_BORDER_OFFSET}px solid rgb(0 0 0/.7);
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  position: absolute;
  box-sizing: content-box;
`

const CropDotContainer = styled(CropSquare)`
  border: none;
  overflow: hidden;
  padding: 15px;
  margin: -15px;
`

const CropDotContainer2 = styled.div`
  flex: 1;
  position: relative;
  touch-action: none;
`

const CropDot = styled.div`
  position: absolute;
  bottom: -9px;
  right: -9px;
  width: 18px;
  height: 18px;
  border-radius: 10px;
  box-shadow: 0 0 3px rgb(0 0 0/.7);
  background: white;
  touch-action: none;
`

const Previews = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  overflow: hidden;
`

const Preview = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const AndroidPreview = styled.div`
  overflow: hidden;
  width: ${APP_ICON_SIZE}px;
  height: ${APP_ICON_SIZE}px;
  position: relative;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ $bgColor }) => $bgColor};
`

const AndroidPreviewImage = styled.img`
  position: absolute;
  filter: blur(2px);
`

const AndroidPreviewImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(255 255 255/.5);
`

const AndroidPreviewInner = styled.div`
  position: relative;
  overflow: hidden;
  width: ${APP_ICON_INNER_SIZE}px;
  height: ${APP_ICON_INNER_SIZE}px;
  position: relative;
  border-radius: 5px;
  border: 1px solid white;
  background: ${({ $bgColor }) => $bgColor};
`

const AndroidPreviewInnerImage = styled.img`
  position: absolute;
`

const IOSPreview = styled.div`
  overflow: hidden;
  width: ${APP_ICON_SIZE}px;
  height: ${APP_ICON_SIZE}px;
  position: relative;
  border-radius: 8px;
  background: ${({ $bgColor }) => $bgColor};
`

const IOSPreviewImage = styled.img`
  position: absolute;
`

const IOSMaskImage = styled.img`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
`

const AndroidMaskImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 25px;
`

const PreviewText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: ${APP_ICON_SIZE * 1.2}px;
  margin-top: 5px;
  font-size: 11px;
`

const HeaderPreview = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 5px 15px;
`

const HeaderPreviewImageContainer = styled.div`
  overflow: hidden;
  border-radius: 5px;
  width: ${HEADER_ICON_SIZE}px;
  height: ${HEADER_ICON_SIZE}px;
  position: relative;
  margin-right: 8px;
  background: ${({ $bgColor }) => $bgColor};
`

const HeaderPreviewImage = styled.img`
  position: absolute;
`

const HeaderText = styled.div`
  font-weight: 300;
  font-size: 18px;
`

const HeaderTextShortName = styled.span`
  display: inline-block;
  font-weight: 500;
`

const Platform = styled.div`
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 8px;
`

const Heading = styled.div`
  font-weight: 300;
  font-size: 14px;
  margin: 5px 0 -5px;
`

const CSSBImagePreview = ({
  bgColor,
  baseImage,
  setBaseImage,
  shortName,
  setImageEditInfo,
}) => {

  const imageRef = useRef()
  const eventStartInfo = useRef()
  const [ naturalImageSize, setNaturalImageSize ] = useState({})
  const { naturalWidth=0, naturalHeight=0 } = naturalImageSize
  let [ viewSize, setViewSize ] = useState(BASE_IMAGE_WIDTH)
  let [ position, setPosition ] = useState({ top: 0, left: 0 })

  const onLoadImage = useCallback(
    () => {
        const { naturalWidth, naturalHeight } = imageRef.current
        setNaturalImageSize({
          naturalWidth,
          naturalHeight,
        })
    },
    [ setNaturalImageSize ],
  )

  let { top, left } = position
  const baseImageRatio = BASE_IMAGE_WIDTH / naturalWidth
  const minViewSize = MIN_SIZE * baseImageRatio
  const maxTop = naturalHeight * baseImageRatio - minViewSize
  const maxLeft = BASE_IMAGE_WIDTH - minViewSize
  top = Math.min(Math.max(top, 0), maxTop)
  left = Math.min(Math.max(left, 0), maxLeft)
  const maxViewSize = Math.min(BASE_IMAGE_WIDTH - left, naturalHeight * baseImageRatio - top)

  viewSize = Math.min(Math.max(viewSize, minViewSize), maxViewSize)
  const headerImageToBaseImageRatio = HEADER_ICON_SIZE / viewSize
  const appIconImageToBaseImageRatio = APP_ICON_SIZE / viewSize
  const appIconInnerImageToBaseImageRatio = APP_ICON_INNER_SIZE / viewSize

  const getViewSize = useInstanceValue(viewSize)
  const getPosition = useInstanceValue({ top, left })

  const cropSquareStyle = {
    top: top - MASK_BORDER_OFFSET,
    left: left - MASK_BORDER_OFFSET,
    width: viewSize,
    height: viewSize,
  }

  const cropDotContainerStyle = {
    ...cropSquareStyle,
    top: top,
    left: left,
  }

  const headerPreviewImageStyle = {
    width: (BASE_IMAGE_WIDTH / viewSize) * HEADER_ICON_SIZE,
    left: left * -headerImageToBaseImageRatio,
    top: top * -headerImageToBaseImageRatio,
  }

  const androidPreviewImageStyle = {
    width: (BASE_IMAGE_WIDTH / viewSize) * APP_ICON_SIZE,
    left: left * -appIconImageToBaseImageRatio,
    top: top * -appIconImageToBaseImageRatio,
  }

  const androidPreviewInnerImageStyle = {
    width: (BASE_IMAGE_WIDTH / viewSize) * APP_ICON_INNER_SIZE,
    left: left * -appIconInnerImageToBaseImageRatio,
    top: top * -appIconInnerImageToBaseImageRatio,
  }

  const iOSPreviewImageStyle = {
    width: (BASE_IMAGE_WIDTH / viewSize) * APP_ICON_SIZE,
    left: left * -appIconImageToBaseImageRatio,
    top: top * -appIconImageToBaseImageRatio,
  }

  const clearBaseImage = useCallback(() => setBaseImage(), [ setBaseImage ])

  const resize = useCallback(
    ({ clientX, clientY }) => {
      if(!eventStartInfo.current) return
      const { startViewSize, startClientX, startClientY } = eventStartInfo.current
      setViewSize(startViewSize + Math.min(clientX - startClientX, clientY - startClientY))
    },
    [ setViewSize ],
  )

  const move = useCallback(
    ({ clientX, clientY }) => {
      if(!eventStartInfo.current) return
      const { startViewSize, startPosition, startClientX, startClientY } = eventStartInfo.current
      let left = startPosition.left + (clientX - startClientX)
      let top = startPosition.top + (clientY - startClientY)

      const baseImageRatio = BASE_IMAGE_WIDTH / naturalWidth
      const maxTop = naturalHeight * baseImageRatio - startViewSize
      const maxLeft = BASE_IMAGE_WIDTH - startViewSize
      top = Math.min(Math.max(top, 0), maxTop)
      left = Math.min(Math.max(left, 0), maxLeft)

      setPosition({ left, top })
    },
    [ naturalWidth, naturalHeight ],
  )

  const finishAdjustment = useCallback(
    () => {
      eventStartInfo.current = null
      setPosition(getPosition())
      setViewSize(getViewSize())
    },
    [ setPosition, getPosition, setViewSize, getViewSize ],
  )

  const startAdjustment = useCallback(
    ({ clientX, clientY }) => {
      eventStartInfo.current = {
        startViewSize: getViewSize(),
        startPosition: getPosition(),
        startClientX: clientX,
        startClientY: clientY,
      }
    },
    [ getViewSize, getPosition ],
  )

  const onTouchStart = useCallback(
    (resizeOrMove, event) => {

      preventDefaultEvent(event)

      const onTouchEnd = () => {
        document.body.removeEventListener('touchmove', onTouchMove)
        document.body.removeEventListener('touchend', onTouchEnd)
        document.body.removeEventListener('touchcancel', onTouchEnd)
        finishAdjustment()
      }

      const onTouchMove = ({ touches }) => {
        if(touches.length !== 1) {
          onTouchEnd()
        } else {
          resizeOrMove(touches[0])
        }
      }

      document.body.addEventListener('touchmove', onTouchMove)
      document.body.addEventListener('touchend', onTouchEnd)
      document.body.addEventListener('touchcancel', onTouchEnd)

      startAdjustment(event.touches[0])

    },
    [ startAdjustment, finishAdjustment ],
  )

  const onMouseDown = useCallback(
    (resizeOrMove, event) => {

      preventDefaultEvent(event)

      const onMouseUp = () => {
        document.body.removeEventListener('mousemove', resizeOrMove)
        document.body.removeEventListener('mouseup', onMouseUp)
        document.body.removeEventListener('mouseleave', onMouseUp)
        finishAdjustment()
      }

      document.body.addEventListener('mousemove', resizeOrMove)
      document.body.addEventListener('mouseup', onMouseUp)
      document.body.addEventListener('mouseleave', onMouseUp)

      startAdjustment(event)

    },
    [ startAdjustment, finishAdjustment ],
  )

  const onTouchStartResize = useCallback(event => onTouchStart(resize, event), [ onTouchStart, resize ])
  const onTouchStartMove = useCallback(event => onTouchStart(move, event), [ onTouchStart, move ])
  const onMouseDownResize = useCallback(event => onMouseDown(resize, event), [ onMouseDown, resize ])
  const onMouseDownMove = useCallback(event => onMouseDown(move, event), [ onMouseDown, move ])

  useLayoutEffectAsync(
    () => {
      setViewSize(BASE_IMAGE_WIDTH)
      setPosition({ top: 0, left: 0 })
      setNaturalImageSize({})
    },
    [ baseImage ],
  )

  useLayoutEffectAsync(
    () => {
      setImageEditInfo({
        bgColor,
        url: baseImage,
        x: (left / baseImageRatio) || 0,
        y: (top / baseImageRatio) || 0,
        width: (viewSize / baseImageRatio) || 1024,
        height: (viewSize / baseImageRatio) || 1024,
      })
    },
    [ baseImage, baseImageRatio, left, top, viewSize ],
  )

  return (
    <>

      <Container>

        <AdjustmentContainer $hide={!naturalWidth}>

          <BaseImageContainer>
            <BaseImage
              className="dark-mode-exempt"
              src={baseImage}
              ref={imageRef}
              onLoad={onLoadImage}
            />
            <CropSquare style={cropSquareStyle} />
          </BaseImageContainer>

          <CropDotContainer style={cropDotContainerStyle}>
            <CropDotContainer2
              onTouchStart={onTouchStartMove}
              onMouseDown={onMouseDownMove}
            >
              <CropDot
                onTouchStart={onTouchStartResize}
                onMouseDown={onMouseDownResize}
              />
            </CropDotContainer2>
          </CropDotContainer>

          <ClearImageIconButton
            onClick={clearBaseImage}
          >
            <CloseIcon />
          </ClearImageIconButton>

        </AdjustmentContainer>

        <Heading>
          {i18n("Preview")}
        </Heading>

        <HeaderPreview>
          <HeaderPreviewImageContainer
            $bgColor={bgColor}
            className="dark-mode-exempt"
          >
            <HeaderPreviewImage
              src={baseImage}
              style={headerPreviewImageStyle}
            />
            <IOSMaskImage src={`https://cdn.biblearc.com/cssb_app_icon_overlays/basic.png`} />
          </HeaderPreviewImageContainer>
          <HeaderText>
            {i18nReact("The {{short_name}} Study Bible", {
              short_name: (
                <HeaderTextShortName>
                  {shortName}
                </HeaderTextShortName>
              )
            })}
          </HeaderText>
        </HeaderPreview>

        <Previews>

          <Preview>
            <Platform>
              {i18n("iOS")}
            </Platform>
            <IOSPreview
              $bgColor={bgColor}
              className="dark-mode-exempt"
            >
              <IOSPreviewImage
                src={baseImage}
                style={iOSPreviewImageStyle}
              />
              <IOSMaskImage src={`https://cdn.biblearc.com/cssb_app_icon_overlays/basic.png`} />
            </IOSPreview>
            <PreviewText>
              {shortName}
            </PreviewText>
          </Preview>

          <Preview>
            <Platform>
              {i18n("Android")}
            </Platform>
            <AndroidPreview
              $bgColor={bgColor}
              className="dark-mode-exempt"
            >
              <AndroidPreviewImage
                src={baseImage}
                style={androidPreviewImageStyle}
              />
              <AndroidPreviewImageOverlay />
              <AndroidPreviewInner
                $bgColor={bgColor}
              >
                <AndroidPreviewInnerImage
                  src={baseImage}
                  style={androidPreviewInnerImageStyle}
                />
                <AndroidMaskImage src={`https://cdn.biblearc.com/cssb_app_icon_overlays/basic.png`} />
              </AndroidPreviewInner>
            </AndroidPreview>
            <PreviewText>
              {shortName}
            </PreviewText>
          </Preview>

        </Previews>

      </Container>

    </>
  )
}


export default memo(CSSBImagePreview)