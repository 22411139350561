import React, { memo, useMemo } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import { getLanguageInfo } from '@bibletags/bibletags-ui-helper'

import useDataQuery from '../../../hooks/useDataQuery'
import { getOrigVersionInfo } from '../../../utils/misc'
import i18nReact from '../../../utils/i18nReact'

import Header from '../../common/Header'
import Loading from '../../common/Loading'

import allVersionsQuery from '../../../graphql/queries/allVersions'
import NavLinkOrAWithDisable from '../../common/NavLinkOrAWithDisable'

const Container = styled.div`
  flex: 1;
  overflow: auto;
  padding: 10px 20px 20px;
  position: relative;
`

const Content = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  font-size: 16px;
`

const LanguageLine = styled.h1`
  font-size: 22px;
  margin-top: 30px;
`

const Language = styled.span`
  display: inline-block;
`

const Plus = styled.span`
  font-weight: 300;
`

const StyledNavLinkOrAWithDisable = styled(NavLinkOrAWithDisable)`
  text-decoration: none;
`

const Version = styled.div`
`

const VersionName = styled.span`
  display: inline-block;
`

const Versions = ({ ...props }) => {

  const { versions: { versions }={} } = useDataQuery({ versionsQuery: allVersionsQuery })

  const sortedVersions = useMemo(
    () => (
      versions
        ? ([
          getOrigVersionInfo(),
          // getLXXVersionInfo(),
          ...(
            [ ...versions ]
              .filter(({ type }) => type !== 'PERSONAL')
              .sort((a,b) => getLanguageInfo(a.languageId).nativeName < getLanguageInfo(b.languageId).nativeName ? -1 : 1)
          ),
        ])
        : null
    ),
    [ versions ],
  )

  let lastLanguageId

  return (
    <>

      <Header {...props} />

      <Container>
        <Content>

          {!sortedVersions && <Loading />}

          {(sortedVersions || []).map(({ id, abbr, name, languageId }) => {

            let languageHeading = null
            if(languageId !== lastLanguageId) {
              languageHeading = (
                <LanguageLine>
                  {languageId.split('+').map((lId, idx) => {
                    const { nativeName=`?`, englishName } = getLanguageInfo(lId)
                    return (
                      <React.Fragment key={lId}>
                        {idx > 0 &&
                          <>
                            {` `}
                            <Plus>{i18n("+", "combination character")}</Plus>
                            {` `}
                          </>
                        }
                        <Language>
                          {
                            (!englishName || englishName === nativeName)
                              ? nativeName
                              : (
                                i18n("{{title}} ({{subtitle}})", {
                                  title: nativeName,
                                  subtitle: englishName,
                                })
                              )
                          }
                        </Language>
                      </React.Fragment>
                    )
                  })}
                </LanguageLine>
              )
              lastLanguageId = languageId
            }

            return (
              <>

                {languageHeading}

                <StyledNavLinkOrAWithDisable exact to={`/version/${id}`} keepLinkStyle>
                  <Version>
                    {i18nReact("{{title}} ({{subtitle}})", {
                      title: (
                        <VersionName>
                          {name}
                        </VersionName>
                      ),
                      subtitle: abbr,
                    })}
                  </Version>
                </StyledNavLinkOrAWithDisable>

              </>
            )

          })}

        </Content>
      </Container>

    </>
  )
}

export default memo(Versions)