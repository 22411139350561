import { memo, useCallback, useState } from 'react'
import styled from 'styled-components'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { i18n } from 'inline-i18n'
import { useMutation } from '@apollo/client'
import copy from 'copy-to-clipboard'

import useSimpleToggle from '../../../hooks/useSimpleToggle'
import useMutationContext from '../../../hooks/useMutationContext'
import i18nReact from '../../../utils/i18nReact'

import RelativeTime from '../../common/RelativeTime'
import AlertsItem from '../../common/AlertsItem'
import FadedLoading from '../../common/FadedLoading'
import ConfirmDialog from '../../common/ConfirmDialog'

import updateGlobalAlertsItemMutation from '../../../graphql/mutations/updateGlobalAlertsItem'
import deleteGlobalAlertsItemMutation from '../../../graphql/mutations/deleteGlobalAlertsItem'
import NotificationsItem from '../../common/NotificationsItem'

const Container = styled.div`
  margin-bottom: 30px;
`

const Alert = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  margin-bottom: 7px;

  & > * {
    flex-shrink: 0;
  }
`

const AlertsItemContainer = styled.div`
  width: 270px;
  margin-bottom: -10px;
`

const Info = styled.div`
  margin-left: 10px;
  width: 180px;
  line-height: 1.2;
  display: flex;
  flex-direction: column;
  gap: 7px;
`

const InfoSectionLabel = styled.div`
  font-size: 11px;
  font-weight: 300;
`

const RefCode = styled.div`
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 3px;
`

const Expiration = styled.div`
  font-size: 12px;
  font-weight: 500;
`

const Expired = styled.div`
  color: ${({ theme }) => theme.palette.primary.main};
`

const Deleted = styled.div`
  color: ${({ theme }) => theme.palette.tertiary.main};
`

const ActiveVsDismissed = styled.div`
  font-size: 12px;
  font-weight: 300;
`

const Active = styled.div`
`

const Dismissed = styled.div`
`

const Action = styled.div`
  font-weight: 400;
  font-size: 12px;
  margin-right: 3px;
  color: ${({ theme }) => theme.palette.secondary.main};
  text-decoration: underline;
  transition: opacity .25s ease-in-out;
  display: inline-block;

  &:hover {
    opacity: .5;
    cursor: pointer;
  }
`

const StyledKeyboardDatePicker = styled(KeyboardDatePicker)`
  display: none;
`


const GlobalAlertsItemRow = ({
  globalAlertsItem,
  setTextSearch,
}) => {

  const {
    id,
    title,
    content,
    // info,
    expiresAt,
    deletedAt,
    numUndismissed,
    numDismissed,
  } = globalAlertsItem

  const expired = expiresAt < Date.now()

  const [ updating, setUpdating ] = useState()
  const [ editingExpiresAt, toggleEditingExpiresAt ] = useSimpleToggle()
  const [ confirmDelete, toggleConfirmDelete ] = useSimpleToggle()

  const [ updateGlobalAlertsItem ] = useMutation(updateGlobalAlertsItemMutation)
  const [ deleteGlobalAlertsItem ] = useMutation(deleteGlobalAlertsItemMutation)
  const context = useMutationContext()

  const copyContent = useCallback(
    () => {
      copy(content, { format: `text/plain` })
      alert(`Content copied to clipboard.`)
    },
    [ content ],
  )

  const handleExpiresAtChange = useCallback(
    async date => {

      setUpdating(true)

      await updateGlobalAlertsItem({
        variables: {
          id,
          input: {
            expiresAt: date.getTime(),
          },
        },
        context,
      })

      setUpdating(false)

    },
    [ id, updateGlobalAlertsItem, context ],
  )

  const goDelete = useCallback(
    async () => {

      toggleConfirmDelete()
      setUpdating(true)

      await deleteGlobalAlertsItem({
        variables: {
          id,
        },
        context,
      })

      setUpdating(false)

    },
    [ toggleConfirmDelete, deleteGlobalAlertsItem, context, id ],
  )

  const editExpirationAction = (
    <>
      {` `}
      <Action
        onClick={toggleEditingExpiresAt}
      >
        {i18n("edit")}
      </Action>
      <StyledKeyboardDatePicker
        open={editingExpiresAt}
        onClose={toggleEditingExpiresAt}
        views={['date']}
        minDate={new Date()}
        maxDate={new Date(Date.now() + 1000*60*60*24*365)}
        value={new Date(expiresAt)}
        onChange={handleExpiresAtChange}      
      />
    </>
  )

  return (
    <Container>

      <RefCode>
        {id.split('\n')[2] || `??`}
      </RefCode>

      <InfoSectionLabel>
        {i18n("Notification")}
      </InfoSectionLabel>

      <NotificationsItem
        {...globalAlertsItem}
      />

      <InfoSectionLabel>
        {i18n("Alert")}
      </InfoSectionLabel>

      <Alert>

        <AlertsItemContainer>
          <AlertsItem
            {...globalAlertsItem}
          />
        </AlertsItemContainer>

        <Info>

          <InfoSectionLabel>
            <Action
              onClick={copyContent}
            >
              {i18n("copy")}
            </Action>
            {` `}
            {!deletedAt && !expired &&
              <>
                <Action
                  onClick={toggleConfirmDelete}
                >
                  {i18n("delete")}
                </Action>
                <ConfirmDialog
                  open={confirmDelete}
                  onCancel={toggleConfirmDelete}    
                  onConfirm={goDelete}
                  confirmButtonLabel={i18n("Delete")}
                  explanation={i18n("Are you sure you want to delete the alert entitled “{{title}}” for all users?", { title })}
                  doubleConfirm
                  loading={updating}
                  color="secondary"
                />
              </>
            }
          </InfoSectionLabel>

          <ActiveVsDismissed>
            {!expired && !deletedAt &&
              <Active>
                {i18n("{{percent}}% ({{num}}) active", {
                  percent: Math.round((numUndismissed / (numUndismissed + numDismissed)) * 100) || 0,
                  num: numUndismissed,
                })}
              </Active>
            }
            <Dismissed>
              {!expired && !deletedAt && i18n("{{percent}}% ({{num}}) dismissed", {
                percent: Math.round((numDismissed / (numUndismissed + numDismissed)) * 100) || 0,
                num: numDismissed,
              })}
              {expired && !deletedAt && i18n("{{percent}}% ({{num}}) were dismissed", {
                percent: Math.round((numDismissed / (numUndismissed + numDismissed)) * 100) || 0,
                num: numDismissed,
              })}
            </Dismissed>
          </ActiveVsDismissed>

          <Expiration>
            {!expired && !deletedAt &&
              <>
                {i18nReact("Expires: {{date}}", {
                  date: (
                    <RelativeTime date={expiresAt} />
                  ),
                })}
                {editExpirationAction}
              </>
            }
            <Expired>
              {expired && !deletedAt &&
                <>
                  {i18nReact("Expired: {{date}}", {
                    date: (
                      <RelativeTime date={expiresAt} />
                    ),
                  })}
                  {editExpirationAction}
                </>
              }
            </Expired>
            <Deleted>
              {!!deletedAt && i18nReact("Deleted: {{date}}", {
                date: (
                  <RelativeTime date={deletedAt} />
                ),
              })}
            </Deleted>
          </Expiration>

        </Info>

        {updating && <FadedLoading />}

      </Alert>

    </Container>
  )
}

export default memo(GlobalAlertsItemRow)