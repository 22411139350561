import { memo, useCallback, useState } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'

import {
  PRICING,
  WHATEVER_YOU_CAN_AFFORD_PERCENTAGE_DISCOUNTS,
} from '../../../utils/constants'
import useIsLoggedIn from '../../../hooks/useIsLoggedIn'
import i18nReact from '../../../utils/i18nReact'

import Header from "../../common/Header"
import CustomCheckbox from '../../common/CustomCheckbox'
import LinkButton from '../../common/LinkButton'
import NavLinkOrAWithDisable from '../../common/NavLinkOrAWithDisable'
import SignInButton from '../../common/SignInButton'
import CountrySelect from './CountrySelect'

const Container = styled.div`
  flex: 1;
  overflow: auto;
  padding: 10px 20px 80px;
  background-color: white;
`

const Content = styled.div`
  max-width: 600px;
  margin: 0 auto;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 300px;
`

const Why = styled.div`
  margin: 40px 0 20px;
  padding: 15px 20px 200px;
  background-image: url("/church.jpg");
  background-position-y: bottom;
  background-size: cover;
`

const GetStarted = styled.div`
  font-size: 20px;
  padding: 20px 0;
  font-weight: bold;
`

const YesOrNo = styled.div`
  font-size: 22px;
  margin: 40px 0 10px;
  text-align: center;
`

const SelectPricing = styled.div`
  font-size: 14px;
  margin-bottom: 30px;
`

const RequestFree = styled.div`
  font-size: 13px;
  margin: 20px 0;
  color: ${({ theme }) => theme.palette.grey[500]};
`

const PricingOptions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const PricingOption = styled.div`
  display: flex;
  align-items: center;
  margin: 0 -10px;
  padding: 12px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[100]};
`

const Discount = styled.div`
  padding: 0 10px;
  width: 100px;
  text-align: right;
  margin-left: -40px;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.primary.dark};
`

const Cost = styled.div`
  align-self: center;
  color: ${({ theme }) => theme.palette.grey[500]};
  font-weight: 400;
`

const Price = styled.span`
  font-weight: bold;
  color: black;
`

const Billed = styled.div`
  align-self: center;
  font-size: 11px;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.grey[900]};
  margin-top: 3px;
`

const StyledLinkButton = styled(LinkButton)`
  text-transform: none;
  line-height: 1.2;
  padding: 0;
  width: 140px;
  height: 60px;
  margin: 0 5px;

  ${({ disabled, theme }) => !disabled ? `` : `
    background: ${theme.palette.grey[100]};
    border-color: ${theme.palette.grey[100]} !important;
  `}

  .MuiButton-label {
    flex-direction: column;
  }
`

const StyledSignInButton = styled(SignInButton)`
  margin: 20px 0;
`

const WhateverYouCanAfford = ({ ...props }) => {

  const isLoggedIn = useIsLoggedIn()

  const [ country, setCountry ] = useState(null)
  const [ affirmed, setAffirmed ] = useState(false)

  const onCountryChange = useCallback(
    (event, country) => {
      setCountry(country)
      setAffirmed(false)
    },
    [],
  )

  const onAffirmedChange = useCallback((event, value) => setAffirmed(value) , [])

  return (
    <>

      <Header {...props} />

      <Container>
        <Content>

          <Why className="dark-mode-exempt">
            <b>
              {i18n("We love Jesus and we love his Church.")}
            </b>
            {` `}
            {i18n("And we are eager to see it strengthened throughout the world.")}
            {` `}
            {i18n("That is why we are delighted to offer our Biblearc TOOLS subscription to users in the developing world under a whatever-you-can-afford policy.")}
          </Why>

          {!isLoggedIn &&
            <StyledSignInButton
              label={i18n("Sign in to get started")}
            />
          }

          {isLoggedIn &&
            <>

              <GetStarted>
                {i18n("Get started by selecting your country.")}
              </GetStarted>

              <CountrySelect
                value={country}
                onChange={onCountryChange}
                disabled={affirmed}
              />

              {!!country &&
                <CustomCheckbox
                  label={i18n("Before God, I affirm that this is my primary country of residence.")}
                  checked={affirmed}
                  disabled={affirmed}
                  onChange={onAffirmedChange}
                />
              }

              {affirmed &&
                <YesOrNo>
                  {
                    (country || {}).qualifies
                      ? i18n("Good news! Your country qualifies.")
                      : i18n("Sorry. Your country does not qualify.")
                  }
                </YesOrNo>
              }

              {affirmed && !!(country || {}).qualifies &&
                <>

                  <SelectPricing>
                    {i18n("Select your whatever-you-can-afford pricing.")}
                  </SelectPricing>

                  <PricingOptions>

                    {WHATEVER_YOU_CAN_AFFORD_PERCENTAGE_DISCOUNTS.map(discount => (
                      <PricingOption key={discount}>

                        <Discount>
                          {i18n("{{percentage}}% off", {
                            percentage: discount,
                          })}
                        </Discount>

                        <StyledLinkButton
                          to={{
                            pathname: "/subscribe",
                            search: `?plan=TOOLS&term=MONTHLY&donation=0`,
                            state: { discount },
                          }}
                          color="primary"
                          variant="outlined"
                        >
                          <Cost>
                            {i18nReact("{{amount}} / month", {
                              amount: (
                                <Price>
                                  {
                                    i18n("${{amount}}", {  // eslint-disable-line no-template-curly-in-string
                                      amount: (PRICING.TOOLS.MONTHLY * ((100-discount)/100)).toFixed(2),
                                    })
                                  }
                                </Price>
                              ),
                            })}
                          </Cost>
                          <Billed>
                            {i18n("Billed monthly")}
                          </Billed>
                        </StyledLinkButton>

                        <StyledLinkButton
                          to={{
                            pathname: "/subscribe",
                            search: `?plan=TOOLS&term=ANNUALLY&donation=0`,
                            state: { discount },
                          }}
                          color="primary"
                          variant="outlined"
                        >
                          <Cost>
                            {i18nReact("{{amount}} / month", {
                              amount: (
                                <Price>
                                  {
                                    i18n("${{amount}}", {  // eslint-disable-line no-template-curly-in-string
                                      amount: ((PRICING.TOOLS.ANNUALLY / 12) * ((100-discount)/100)).toFixed(2),
                                    })
                                  }
                                </Price>
                              ),
                            })}
                          </Cost>
                          <Billed>
                            {i18n("${{amount}} billed annually", { amount: (PRICING.TOOLS.ANNUALLY * ((100-discount)/100)).toFixed(2) })  /* eslint-disable-line no-template-curly-in-string */}
                          </Billed>
                        </StyledLinkButton>

                      </PricingOption>
                    ))}

                    <RequestFree>
                      {i18nReact("Or click {{here}} to request a free Biblearc TOOLS subscription.", {
                        here: (
                          <NavLinkOrAWithDisable
                            exact
                            to={`/request-free-tools?country=${encodeURIComponent(country.label)}`}
                          >
                            {i18n("here")}
                          </NavLinkOrAWithDisable>
                        )
                      })}
                    </RequestFree>

                  </PricingOptions>

                </>
              }

            </>
          }

        </Content>
      </Container>

    </>
  )
}


export default memo(WhateverYouCanAfford)