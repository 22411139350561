import { memo, useMemo } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import { getBibleBookAbbreviatedName, getBibleBookName, getConcentricCircleScopes, isRTLText } from '@bibletags/bibletags-ui-helper'
import { getRefFromLoc } from '@bibletags/bibletags-versification'

import useFormattedSearchValue from '../../hooks/useFormattedSearchValue'
import useTagSet from '../../hooks/useTagSet'
import useVersionInfo from '../../hooks/useVersionInfo'
import useInterlinearInfo from '../../hooks/useInterlinearInfo'
import i18nReact from '../../utils/i18nReact'

import SearchSuggestionMenuItem from './SearchSuggestionMenuItem'
import SearchSuggestionSearchWord from './SearchSuggestionSearchWord'
import MenuDivider from './MenuDivider'

const ExtraLines = styled.div`
  padding: 6px 16px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  cursor: default;
`

const ExtraLine = styled.div`
  font-weight: 200;
  font-size: 12px;
  line-height: 1;
`

const ExtraLineContent = styled.span`
  font-weight: 300;
  display: inline-block;
  direction: ${({ $isRTL }) => $isRTL ? "rtl" : "ltr"};
`

const SearchSuggestionTranslationMenuItems = ({
  words: notUsedProp,  // have this here removes it from otherProps
  selectionStart,
  selectionEnd,
  versionId,
  baseSearchStr,
  bookId,
  onClose,
  ...otherProps
}) => {

  const { safeVersionAbbr } = useVersionInfo(versionId)

  const concentricCircleScopes = useMemo(() => getConcentricCircleScopes(bookId), [ bookId ])

  const formattedValue = useFormattedSearchValue({ value: baseSearchStr })

  const words = baseSearchStr.split(/ +/g)

  const {
    tags,
    tagSetStatus,
    translationWords,
    originalWordPieces,
  } = useTagSet({
    passageRef: getRefFromLoc(selectionStart.loc),
    versionId,
    wordNumberInVerse: selectionStart.wordNumberInVerse,
  })

  const { searchInfoByTranslationWordNumberInVerse } = useInterlinearInfo({
    versionId,
    tags,
    tagSetStatus,
    translationWords,
    originalWordPieces,  
  })

  const {
    originalSearchStr,
    phrase,
    translation,
  } = searchInfoByTranslationWordNumberInVerse[selectionStart.wordNumberInVerse] || {}

  let originalSearchBlock = !!originalSearchStr && (
    <>
      <MenuDivider />
      <SearchSuggestionMenuItem
        searchText={originalSearchStr}
        onClose={onClose}
      >
        {i18nReact("Search in {{language}}", {
          language: (
            bookId < 40
              ? i18n("Hebrew")
              : i18n("Greek")
          ),
        })}
      </SearchSuggestionMenuItem>
      <ExtraLines>
        <ExtraLine>
          {i18n("{{category}}:", { category: i18n("Original") })}
          {` `}
          <ExtraLineContent $isRTL={isRTLText({ languageId: `heb+grc`, bookId })}>
            {phrase.map((phrasePart, idx) => (
              <span
                key={idx}
                style={{ color: phrasePart.color }}
              >
                {phrasePart.text}
              </span>
            ))}
          </ExtraLineContent>
        </ExtraLine>
        <ExtraLine>
          {i18n("{{category}}:", { category: safeVersionAbbr })}
          {` `}
          <ExtraLineContent>
            {i18n("“{{quote}}”", { quote: translation })}
          </ExtraLineContent>
        </ExtraLine>
      </ExtraLines>
    </>
  )

  if(words.length > 1) {

    if(
      selectionStart.loc !== selectionEnd.loc
      || (
        originalSearchStr
        && Array(selectionEnd.wordNumberInVerse - selectionStart.wordNumberInVerse).fill().some((x, idx) => (
          (searchInfoByTranslationWordNumberInVerse[selectionStart.wordNumberInVerse + idx + 1] || {}).originalSearchStr !== originalSearchStr
        ))
      )
    ) {
      originalSearchBlock = null
    }
  
    return (
      <div {...otherProps} >

        <SearchSuggestionMenuItem
          searchText={baseSearchStr}
          onClose={onClose}
        >
          {i18n("Search these words")}
        </SearchSuggestionMenuItem>

        <SearchSuggestionMenuItem
          searchText={`"${baseSearchStr}"`}
          onClose={onClose}
          isSubItem
        >
          {i18n("Search this exact phrase")}
        </SearchSuggestionMenuItem>

        {words.length >= 3 &&
          <SearchSuggestionMenuItem
            searchText={`${words[0]} ${words.at(-1)}`}
            onClose={onClose}
            isSubItem
          >
            {i18n("Search “{{first_word}}” and “{{last_word}}”", {
              first_word: words[0],
              last_word: words.at(-1),
            })}
          </SearchSuggestionMenuItem>
        }

        {originalSearchBlock}

      </div> 
    )
  }

  return (
    <div {...otherProps} >

      <SearchSuggestionMenuItem
        searchText={baseSearchStr}
        onClose={onClose}
      >
        {i18nReact("Search {{word}}", {
          word: (
            <SearchSuggestionSearchWord>
              {formattedValue}
            </SearchSuggestionSearchWord>
          ),
        })}
      </SearchSuggestionMenuItem>

      {!!bookId &&
        <>

          <SearchSuggestionMenuItem
            searchText={`${baseSearchStr} in:${getBibleBookAbbreviatedName(bookId)}`}
            onClose={onClose}
            isSubItem
          >
            {i18n("{{scope}} only", {
              scope: getBibleBookName(bookId),
            })}
          </SearchSuggestionMenuItem>

          {concentricCircleScopes.map(({ label, scope }) => (
            <SearchSuggestionMenuItem
              key={label}
              searchText={`${baseSearchStr} in:${scope}`}
              onClose={onClose}
              isSubItem
            >
              {i18n("{{scope}} only", {
                scope: label,
              })}
            </SearchSuggestionMenuItem>
          ))}

        </>
      }

      {originalSearchBlock}

    </div>
  )
}

export default memo(SearchSuggestionTranslationMenuItems)