import { memo, useCallback } from 'react'
import { i18n } from 'inline-i18n'
import TextField from '@material-ui/core/TextField'
import { ReactSortable } from "react-sortablejs"
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import CloseIcon from '@material-ui/icons/Delete'

import useInstanceValue from '../../../hooks/useInstanceValue'
import useSimpleToggle from '../../../hooks/useSimpleToggle'
import { cloneObj, getShortUuid } from '../../../utils/misc'
import BibleMapEditorLevelOfCertainty from './BibleMapEditorLevelOfCertainty'
import { datePattern } from './mapDataIsValid'
import i18nReact from '../../../utils/i18nReact'

const Container = styled.div`
  margin: -25px 0 0;
  .sortable-ghost {
    opacity: 0;
  }
`

const SummaryContainer = styled.div`
  margin-top: 5px;
  border-right: 3px solid ${({ theme }) => theme.palette.divider};
  padding-right: 10px;
`

const Name = styled.div`
  margin: 30px 0;
  border-right: 3px solid ${({ theme }) => theme.palette.secondary.main};
  padding-right: 10px;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
`

const Summary = styled.div`
  font-size: 11px;
  padding: 0 7px;
  line-height: 1.2;
`

const SummaryNamePart = styled.span`
  display: inline-block;
  margin-right: 4px;
`

const SummaryName = styled.span`
  font-weight: bold;
`

const SummaryLine = styled.div`
  margin-top: 5px;
`

const SummaryNotes = styled.div`
`

const StyledIconButton = styled(IconButton)`
  padding: 8px;

  .MuiSvgIcon-root {
    font-size: 17px;
  }
`

const StyledButton = styled(Button)`
  margin: -10px 0 10px;
`

const StyledTextField = styled(TextField)`
`

const StyledBibleMapEditorLevelOfCertainty = styled(BibleMapEditorLevelOfCertainty)`
  margin: 0 0 -5px;
  width: 100%;
`

const BibleMapEditorDates = ({
  dates,
  onChange,
  disabled,
  mapLayerIds,
}) => {

  const getDates = useInstanceValue(dates)
  const [ showSummary, toggleShowSummary ] = useSimpleToggle(true)

  const setList = useCallback(
    list => {
      onChange({
        keys: [ `dates` ],
        newValue: list.map(({ chosen, selected, filtered, ...info }) => info),
      })
    },
    [ onChange ],
  )

  const addDate = useCallback(
    () => {
      onChange({
        keys: [ `dates` ],
        newValue: [
          ...getDates(),
          {
            id: getShortUuid(mapLayerIds),
            startDate: ``,
          },
        ],
      })
    },
    [ onChange, getDates, mapLayerIds ],
  )

  if(showSummary) {
    return (
      <SummaryContainer>

        <Row>

          <StyledTextField
            variant="outlined"
            size="small"
            value={dates[0].date || ``}
            data-keys={`dates 0 date`}
            onChange={onChange}
            fullWidth
            disabled={disabled}
            label={`${i18n("Date")}*`}
            placeholder="4 BC - AD 2 [1/1]"
            error={!new RegExp(datePattern).test(dates[0].date || ``)}
          />

          <StyledIconButton
            onClick={toggleShowSummary}
          >
            <ExpandMoreIcon />
          </StyledIconButton>

        </Row>

        <Row>

          <StyledBibleMapEditorLevelOfCertainty
            levelOfCertainty={dates[0].levelOfCertainty || 1}
            onChange={onChange}
            disabled={disabled}
            keys={`dates 0 levelOfCertainty`}
          />

        </Row>

        <Summary>

          {dates.map(({ date, levelOfCertainty, notes }, idx) => (
            <SummaryLine key={date || `none`}>
              {idx > 0 &&
                <SummaryNamePart>
                  {i18nReact("Also: {{extra}}", {
                    extra: (
                      <SummaryName>
                        {date}
                      </SummaryName>
                    ),
                  })}
                </SummaryNamePart>
              }
              {idx > 0 && i18n("Level {{level}} certainty", { level: levelOfCertainty || 1 })}
              {!!notes &&
                <SummaryNotes>
                  {notes}
                </SummaryNotes>
              }
            </SummaryLine>
          ))}

        </Summary>

      </SummaryContainer>
    )
  }

  return (
    <Container>

      <ReactSortable
        list={cloneObj(dates)}
        setList={setList}
        delayOnTouchStart
      >
        {dates.map(({ date, levelOfCertainty, notes }, idx) => (
          <Name key={date || `none`}>

            <Row>

              <StyledTextField
                variant="outlined"
                size="small"
                value={date || ``}
                data-keys={`dates ${idx} date`}
                onChange={onChange}
                fullWidth
                disabled={disabled}
                label={
                  idx === 0
                    ? `${i18n("Date")}*`
                    : `${i18n("Alternative Date {{num}}", { num: idx })}*`
                }
                placeholder="4 BC, 4 BC - AD 2, 4 BC 7/9"
                error={!new RegExp(datePattern).test(date || ``)}
              />

              {idx === 0 &&
                <StyledIconButton
                  onClick={toggleShowSummary}
                >
                  <ExpandLessIcon />
                </StyledIconButton>
              }

              {idx !== 0 &&
                <StyledIconButton
                  onClick={() => {
                    const newValue = [ ...getDates() ]
                    newValue.splice(idx, 1)
                    onChange({
                      keys: [ `dates` ],
                      newValue,
                    })
                  }}
                  disabled={disabled}
                >
                  <CloseIcon />
                </StyledIconButton>
              }

            </Row>

            <Row>

              <StyledBibleMapEditorLevelOfCertainty
                levelOfCertainty={levelOfCertainty || 1}
                onChange={onChange}
                disabled={disabled}
                keys={`dates ${idx} levelOfCertainty`}
              />

            </Row>

            <Row>

              <StyledTextField
                variant="outlined"
                size="small"
                value={notes || ``}
                data-keys={`dates ${idx} notes`}
                onChange={onChange}
                fullWidth
                disabled={disabled}
                label={i18n("Notes")}
                multiline
              />

            </Row>

          </Name>
        ))}
      </ReactSortable>

      <StyledButton
        variant="contained"
        disableElevation
        size="small"
        onClick={addDate}
        fullWidth
        disabled={disabled}
      >
        {i18n("Add alternative date")}
      </StyledButton>

    </Container>
  )
}

export default memo(BibleMapEditorDates)