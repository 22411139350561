import { memo, useCallback, useState, useContext, useMemo } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepButton from '@material-ui/core/StepButton'
import StepContent from '@material-ui/core/StepContent'
import EditIcon from '@material-ui/icons/Edit'
import queryString from 'query-string'
import { useLocation } from "react-router-dom"

import {
  PLANS,
} from '../../../utils/constants'
import { LoggedInUserContext } from '../../../context/LoggedInUser'
import useDataQuery from '../../../hooks/useDataQuery'
import useEffectAsync from '../../../hooks/useEffectAsync'
import { getSubscriptionTermLanguage, getSubscriptionPlanImageSrc } from '../../../utils/misc'
import i18nReact from '../../../utils/i18nReact'

import Header from "../../common/Header"
import Loading from "../../common/Loading"
import SubscriptionStatusNote from "./SubscriptionStatusNote"
import Plans from "./Plans"
import Partner from "./Partner"
import ReviewBeforeCheckout from "./ReviewBeforeCheckout"

import stripeCurrentSaleQuery from '../../../graphql/queries/stripeCurrentSale'
import ContactUsFab from '../../common/ContactUsFab'

const Container = styled.div`
  overflow: auto;
  flex: 1;
  background: white;
`

const PlanContainer = styled(Container)`
  padding: 20px;
  position: relative;
`

const StyledStepper = styled(Stepper)`
  .MuiStepConnector-root {
    flex: none;
  }
`

const StyledStep = styled(Step)`
  display: flex;
  flex-direction: column;
`

const StyledStepButton = styled(StepButton)`

  .MuiStepLabel-label {
    font-size: 18px;
    white-space: nowrap;
  }

  .MuiStepLabel-label .MuiSvgIcon-root {
    height: 17px;
    vertical-align: middle;
    margin: 0 9px;
    opacity: .5;
    transition: transform .2s ease-in-out, opacity .2s ease-in-out;
  }

  &:hover .MuiStepLabel-label .MuiSvgIcon-root {
    opacity: 1;
    transform: scale(1.4);
  }

`

const SaleOnTerm = styled.div`
  display: inline-block;
  background-color: ${({ theme }) => theme.palette.error.dark};
  color: white;
  border-radius: 3px;
  padding: 0 5px;
  font-size: 12px;
  vertical-align: middle;
`

const StyledStepLabel = styled(StepLabel)`
  user-select: none;

  .MuiStepLabel-label {
    font-size: 18px;
    white-space: nowrap;
  }
`

const StyledStepContent = styled(StepContent)`
  flex: 1;
`

const PlanImage = styled.img`
  height: 38px;
  vertical-align: middle;
  margin: -20px -5px;
  position: relative;
  top: -2px;
`

const BilledTerm = styled.span`
  font-weight: 300;
  font-size: 16px;
  margin-left: 7px;
`

const StepDetail = styled.span`
  font-weight: 300;
`

const Subscribe = ({ ...props }) => {

  const user = useContext(LoggedInUserContext)
  const { discount } = useLocation().state || {}

  const { stripeCurrentSale } = useDataQuery({ stripeCurrentSaleQuery })

  const {
    plan: planFromQueryString,
    term: termFromQueryString,
    donation: donationFromQueryString,
  } = queryString.parse(window.location.search)

  let defaultPlan, defaultDonation

  if(
    PLANS.includes(planFromQueryString)
    && [ 'MONTHLY', 'ANNUALLY' ].includes(termFromQueryString)
  ) {
    defaultPlan = {
      plan: planFromQueryString,
      term: termFromQueryString,
    }
  }

  if(!isNaN(parseFloat(donationFromQueryString))) {
    defaultDonation = parseFloat(donationFromQueryString)
  }

  const [ planInfo, setPlanInfo ] = useState(defaultPlan)
  const [ donation, setDonation ] = useState(defaultDonation)
  const [ legacyCredit, setLegacyCredit ] = useState(0)
  const [ readyToShow, setReadyToShow ] = useState(!user)
  const [ redirectingToLogin, setRedirectingToLogin ] = useState(false)

  const setDetailsToCurrentSubscription = useCallback(
    () => {

      const stripeSubscription = (user.activeSubscriptions || []).filter(({ type }) => type === 'STRIPE')[0]
  
      if(stripeSubscription && !defaultPlan) {
        setPlanInfo({
          plan: stripeSubscription.plan,
          term: stripeSubscription.term,
        })
      }

      if(stripeSubscription && !defaultDonation) {
        setDonation(stripeSubscription.additionalDonationAmount || 0)
      }

    },
    [ user, defaultPlan, defaultDonation ],
  )

  // Initially, show existing subscription in selection
  const userId = (user || {}).id
  useEffectAsync(
    async () => {

      if(userId) {
        setDetailsToCurrentSubscription()

        // TEMP
        const stripeSubscription = (user.activeSubscriptions || []).filter(({ type }) => type === 'STRIPE')[0]
        if(!stripeSubscription) {
          try {
            const response = await fetch(`https://biblearc.com/submanagement?cancel_credit_check_user_email=${encodeURIComponent(user.email)}`)
            const { credit } = await response.json()
            setLegacyCredit(credit)
          } catch(err) {}
        }

        setReadyToShow(true)
      }

    },
    [ userId, setDetailsToCurrentSubscription, setReadyToShow ],
  )

  const backToPlanInfo = useCallback(() => setPlanInfo(), [ setPlanInfo ])
  const backToDonation = useCallback(() => setDonation(), [ setDonation ])

  const logInWithPlanSelected = useCallback(
    async ({ plan, term }) => {

      window.sessionSyncAuth.logIn({
        extraQueryParamsForCallbacks: {
          plan,
          term,
        },
      })

      setRedirectingToLogin(true)

    },
    [],
  )

  let activeStep = 0
  if(planInfo) {
    activeStep = 1
    if(donation !== undefined) {
      activeStep = 2
    }
  }

  const { plan, term } = planInfo || {}
  const { planTermCombos=`` } = (stripeCurrentSale || {}).metadata || {}
  const planTermCombosArray = useMemo(() => planTermCombos.split(' '), [ planTermCombos ])
  const thereIsCurrentlyAMonthlySale = useMemo(() => planTermCombosArray.some(combo => combo.split(':')[1] === 'MONTHLY'), [ planTermCombosArray ])
  const thereIsCurrentlyAnAnnualSale = useMemo(() => planTermCombosArray.some(combo => combo.split(':')[1] === 'ANNUALLY'), [ planTermCombosArray ])

  if(!readyToShow) {
    return (
      <>
        <Header {...props} />
        <PlanContainer>
          <Loading />
        </PlanContainer>
      </>
    )
  }

  if(!user) {
    return (
      <>

        <Header {...props} />

        <PlanContainer>
          <Plans
            selectButtonLabel={i18n("Get this plan")}
            setPlanInfo={logInWithPlanSelected}
            stripeCurrentSale={stripeCurrentSale}
          />
          {redirectingToLogin && <Loading />}
        </PlanContainer>

      </>
    )
  }

  const currentActiveStripeSubscription = (user.activeSubscriptions || []).filter(({ type }) => type === 'STRIPE')[0]
  const planOrTermUpdated = (
    plan !== (currentActiveStripeSubscription || {}).plan
    || term !== (currentActiveStripeSubscription || {}).term
  )
  const donationUpdated = (donation || 0).toFixed(2) !== ((currentActiveStripeSubscription || {}).additionalDonationAmount || 0).toFixed(2)
  const subscriptionSelectionStatus = (
    currentActiveStripeSubscription
      ? (
        (planOrTermUpdated || donationUpdated)
          ? 'changed'
          : 'unchanged'
      )
      : 'new'
  )

  const isActiveButCanceled = (currentActiveStripeSubscription || {}).cancelAtPeriodEnd === true
  const thereIsCurrentlyASale = thereIsCurrentlyAMonthlySale || thereIsCurrentlyAnAnnualSale

  const selectedPlan = (
    <>
      <PlanImage src={getSubscriptionPlanImageSrc(plan)} />
      <BilledTerm>
        {i18n("({{parenthetical_text}})", {
          parenthetical_text: getSubscriptionTermLanguage(term)
        })}
      </BilledTerm>
      {!isActiveButCanceled && <EditIcon />}
      {thereIsCurrentlyASale && <SaleOnTerm>{i18n("Sale")}</SaleOnTerm>}                
    </>
  )

  return (
    <>

      <Header {...props}>
        {currentActiveStripeSubscription ? i18n("My Subscription") : i18n("Subscribe")}
      </Header>

      <Container>

        <SubscriptionStatusNote legacyCredit={legacyCredit} />

        <StyledStepper
          activeStep={activeStep}
          orientation="vertical"
        >

          <StyledStep>
            <StyledStepButton
              onClick={backToPlanInfo}
              disabled={isActiveButCanceled}
            >
              {planInfo
                ? (
                  (
                    [ 'new' ].includes(subscriptionSelectionStatus)
                    || plan !== (currentActiveStripeSubscription || {}).plan
                  )
                    ? i18nReact("Selected Plan: {{plan}}", { plan: selectedPlan })
                    : i18nReact("My Plan: {{plan}}", { plan: selectedPlan })
                )
                : i18n("Choose a plan")
              }
            </StyledStepButton>
            <StyledStepContent>
              <Plans
                setPlanInfo={setPlanInfo}
                stripeCurrentSale={stripeCurrentSale}
              />
            </StyledStepContent>
          </StyledStep>

          <StyledStep>
            <StyledStepButton
              onClick={backToDonation}
              disabled={isActiveButCanceled || activeStep === 0}
            >
              {donation !== undefined
                ? (
                  <>
                    {i18nReact("Donation: {{amount}}", {
                      amount: (
                        <StepDetail>
                          {donation
                            ? (
                              term === 'ANNUALLY'
                                ? i18n("${{amount}} / year", {  // eslint-disable-line no-template-curly-in-string
                                  amount: donation.toFixed(2),
                                })
                                : i18n("${{amount}} / month", {  // eslint-disable-line no-template-curly-in-string
                                  amount: donation.toFixed(2),
                                })
                            )
                            : i18n("None")
                          }
                        </StepDetail>
                      ),
                    })}
                    {!(isActiveButCanceled || activeStep === 0) && <EditIcon />}
                  </>
                )
                : i18n("Partner with us")
              }
            </StyledStepButton>
            <StepContent>
              <Partner
                term={term}
                setDonation={setDonation}
              />
            </StepContent>
          </StyledStep>

          <StyledStep>
            <StyledStepLabel
              StepIconProps={
                (activeStep === 2 && subscriptionSelectionStatus === 'unchanged')
                  ? { completed: true }
                  : null
              }
            >
              {subscriptionSelectionStatus === 'unchanged'
                ? i18nReact("Status: {{status}}", {
                  status: (
                    <StepDetail>
                      {isActiveButCanceled
                        ? i18n("Canceled")
                        : i18n("Active")
                      }
                    </StepDetail>
                  ),
                })
                : i18n("Review")
              }
            </StyledStepLabel>
            <StepContent>
              <ReviewBeforeCheckout
                planInfo={planInfo}
                donation={donation}
                subscriptionSelectionStatus={subscriptionSelectionStatus}
                cancelUpdate={setDetailsToCurrentSubscription}
                showing={activeStep === 2}
                legacyCredit={legacyCredit}
                isActiveButCanceled={isActiveButCanceled}
                currentPeriodEndsAt={(currentActiveStripeSubscription || {}).currentPeriodEndsAt}
                currentStripeCurrentCoupon={(currentActiveStripeSubscription || {}).stripeCurrentCoupon}
                currentStripePlan={(currentActiveStripeSubscription || {}).plan}
                stripeCurrentSale={stripeCurrentSale}
                discount={discount}
              />
            </StepContent>
          </StyledStep>

        </StyledStepper>

      </Container>

      <ContactUsFab />

    </>
  )
}


export default memo(Subscribe)

// import { combineItems } from "@bibletags/bibletags-ui-helper"

// <Point>
//   {i18n("View sermons from popular ministries of your choosing, right along side your Bible.")}
//   <PopularMinistriesList>
//     <PopularMinistriesCurrent>
//       {i18n("Currently Available:")}
//     </PopularMinistriesCurrent>
//     {` `}
//     {combineItems(...STUDY_BIBLE_CHANNELS.map(({ label }) => label))}
//   </PopularMinistriesList>
// </Point>
// 
// <Point>
//   {i18n("See the sermons from your local church right next to the passages they address.")}
// </Point>
// 
// <Point>
//   {i18n("Bookmark spots in sermons you want to remember.")}
// </Point>
// 
// <Point>
//   {i18n("Save snapshots and add notes within the Map-Timeline.")}
// </Point>
// 
// <Point>
//   {i18n("Markup your Bible with highlights, underlines, circles, and arrows, and add rich text notes.")}
// </Point>
// 
// <Point>
//   {i18n("Create a library of your own “Look at the Book”-style sketches.")}
// </Point>
