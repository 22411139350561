import { memo, useCallback, useState, useContext } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import Popover from '@material-ui/core/Popover'
import Fade from '@material-ui/core/Fade'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import DeleteIcon from '@material-ui/icons/Delete'

import useGoUpdateProject from '../../../hooks/useGoUpdateProject'
import useInstanceValue from '../../../hooks/useInstanceValue'
import useDuplicateProject from '../../../hooks/useDuplicateProject'
import useEffectAsync from '../../../hooks/useEffectAsync'
import { LoggedInUserContext } from '../../../context/LoggedInUser'
import i18nReact from '../../../utils/i18nReact'

import RelativeTime from '../../common/RelativeTime'
import ProjectStar from '../../common/ProjectStar'
import FadedLoading from '../../common/FadedLoading'

const Container = styled.div`
  padding: 15px;
`

const TopLine = styled.div`
  margin: -8px 0 15px;
  display: flex;
`

const StyledProjectStar = styled(ProjectStar)`
  margin: -8px -10px -5px -20px;
`

const StyledTextField = styled(TextField)`
  input {
    font-size: 18px;
    font-weight: 500;
    padding: 7px 13px;
  }
`

const DetailLine = styled.div`
  font-size: 12px;
  font-weight: 300;
`

const ButtonsLine = styled.div`
  margin: 15px -8px 0;
`

const StyledButton = styled(Button)`
  margin: 0 8px;
`

const StyledDeleteButton = styled(Button)`
  background-color: ${({ theme }) => theme.palette.tertiary.main};
  color: white;

  &:hover {
    background-color: ${({ theme }) => theme.palette.tertiary.dark};
  }
`

const ProjectPopover = ({
  onClose,
  project,
  setDeleted,
  ...otherProps
}) => {

  const user = useContext(LoggedInUserContext)
  const { hasToolsPlan } = user || {}

  const [ goUpdateProject, goDeleteProject ] = useGoUpdateProject({ project })

  const {
    name,
    createdAt,
    savedAt,
  } = project

  const [ nameInEdit, setNameInEdit ] = useState(name)

  const getName = useInstanceValue(name)
  const getNameInEdit = useInstanceValue(nameInEdit)

  const [ goDuplicateProject, duplicating ] = useDuplicateProject({
    project,
    onComplete: async () => onClose(),
  })

  const onChangeName = useCallback(({ target }) => setNameInEdit(target.value), [])

  const onBlurName = useCallback(
    () => {
      const name = getNameInEdit().trim()
      if(getName() !== name) {
        goUpdateProject({
          name,
        })
      }
    },
    [ getNameInEdit, getName, goUpdateProject ],
  )

  const onKeyPressName = useCallback(
    ({ key }) => {
      if(key === 'Enter') {
        onBlurName()
        onClose()
      }
    },
    [ onBlurName, onClose ],
  )

  const deleteProject = useCallback(
    () => {
      goDeleteProject()
      setDeleted(true)
    },
    [ setDeleted, goDeleteProject ],
  )

  useEffectAsync(
    () => {
      setNameInEdit(name)
    },
    [ name ]
  )

  return (
    <Popover
      {...otherProps}
      disableRestoreFocus
      marginThreshold={5}
      TransitionComponent={Fade}
      onClose={duplicating ? null : onClose}
      anchorReference="anchorPosition"
      anchorPosition={{
        left: 5,
        top: 5,
      }}
    >

      <Container>

        <TopLine>

          <StyledProjectStar
            project={project}
          />

          <StyledTextField
            variant="outlined"
            value={nameInEdit}
            onChange={onChangeName}
            onBlur={onBlurName}
            onKeyPress={onKeyPressName}
            fullWidth
            autoFocus
            placeholder={i18n("Project Name")}
            disabled={!hasToolsPlan}
          />

        </TopLine>

        {/* <DetailLine>
          {i18nReact("Folder: {{folder}}", {
            folder: (

            ),
          })}
        </DetailLine>

        <DetailLine>
          {i18nReact("Tags: {{tags}}", {
            tags: (

            ),
          })}
        </DetailLine> */}

        <DetailLine>
          {i18nReact("Created: {{date}}", {
            date: (
              <RelativeTime date={createdAt} />
            ),
          })}
        </DetailLine>

        <DetailLine>
          {i18nReact("Last saved: {{date}}", {
            date: (
              <RelativeTime date={savedAt} />
            ),
          })}
        </DetailLine>

        <ButtonsLine>

          <StyledButton
            variant="contained"
            size="small"
            disableElevation
            onClick={goDuplicateProject}
            disabled={!hasToolsPlan}
          >
            {i18n("Duplicate Project")}
          </StyledButton>

          {/* <StyledButton
            variant="contained"
            color="primary"
            size="small"
            disableElevation
            // onClick={goDeleteProject}
          >
            {i18n("Download data")}
          </StyledButton> */}

          <StyledDeleteButton
            variant="contained"
            disableElevation
            size="small"
            onClick={deleteProject}
            startIcon={<DeleteIcon />}
          >
            {i18n("Delete")}
          </StyledDeleteButton>

        </ButtonsLine>

      </Container>

      {duplicating && <FadedLoading />}

    </Popover>
  )
}

export default memo(ProjectPopover)